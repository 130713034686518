import React from "react";
import { useState, useEffect } from "react";
import { API_URL } from "../util/util";
import { Button } from "react-bootstrap";
import { showMessage } from "../util/util";
import { useNavigate } from "react-router-dom";

const PQRForm = () => {
  const [clientId, setClientId] = useState(null); // Estado para mantener el ID del cliente
  const authData = localStorage.getItem("authData");
  const [formData, setFormData] = useState({
    Nombre: "",
    CorreoElectronico: "",
    NumeroTelefono: "",
    TipoPQR: "",
    Descripcion: "",
    FechaIncidente: "",
    DetallesAdicionales: "",
    AccionesDeseadas: "",
    DocumentosAdjuntos: null,  // Cambiado a null ya que los archivos adjuntos serán de tipo File
  });

  let navigate = useNavigate();

  useEffect(() => {
    if (!authData) {
      navigate("/");
      showMessage(
        "Error",
        "Para acceder a los formularios, inicia sesión",
        "error",
        "Reintentar"
      );
    } else {
      const parsedAuthData = JSON.parse(authData);
      setClientId(parsedAuthData.id); // Establecer el ID del cliente en el estado
    }
    }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    // Manejar el cambio del input de archivo
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],  // Tomar el primer archivo del array de archivos
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
  
    // Agregar el ID del cliente al formData
    formDataToSend.append("clientId", clientId);
  
    // Agregar los demás datos del formulario al formData
    for (let key in formData) {
      formDataToSend.append(key, formData[key]);
    }
  
    try {
      const response = await fetch(API_URL + "pqr/pqr/request", {
        method: 'POST',
        body: formDataToSend,  // Usar formDataToSend en lugar de JSON.stringify(formData)
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Respuesta del servidor:', data);
        // Puedes realizar acciones adicionales aquí según la respuesta del servidor
      } else {
        console.error('Error en la solicitud al servidor');
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };
  
  

  return (
    <div>
      <h2>Formulario de PQR - Empresa de Logística</h2>
      <p>
        Por favor, complete el siguiente formulario para enviar sus Peticiones,
        Quejas o Reclamos.
      </p>

      <form onSubmit={handleSubmit} className="custom-form">
        <label>Nombre Completo:</label>
        <input
          type="text"
          name="Nombre"
          value={formData.Nombre}
          onChange={handleChange}
        />

        <label>Correo Electrónico:</label>
        <input
          type="email"
          name="CorreoElectronico"
          value={formData.CorreoElectronico}
          onChange={handleChange}
        />

        <label>Número de Teléfono:</label>
        <input
          type="tel"
          name="NumeroTelefono"
          value={formData.NumeroTelefono}
          onChange={handleChange}
        />

        <label>Tipo de PQR:</label>
        <select name="TipoPQR" value={formData.TipoPQR} onChange={handleChange}>
          <option value="">Seleccione...</option>
          <option value="Peticion">Petición</option>
          <option value="Queja">Queja</option>
          <option value="Reclamo">Reclamo</option>
        </select>

        <label>Descripción Detallada:</label>
        <textarea
          name="Descripcion"
          value={formData.Descripcion}
          onChange={handleChange}
        ></textarea>

        <label>Fecha del Incidente:</label>
        <input
          type="date"
          name="FechaIncidente"
          value={formData.FechaIncidente}
          onChange={handleChange}
        />

        <label>Otros Detalles Relevantes:</label>
        <textarea
          name="DetallesAdicionales"
          value={formData.DetallesAdicionales}
          onChange={handleChange}
        ></textarea>

        <label>Acciones Deseadas:</label>
        <textarea
          name="AccionesDeseadas"
          value={formData.AccionesDeseadas}
          onChange={handleChange}
        ></textarea>

        <label>Documentos Adjuntos:</label>
        <input type="file" name="DocumentosAdjuntos" onChange={handleChange} />

        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default PQRForm;
