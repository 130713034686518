import React from "react";
import { useState, useEffect } from "react";
import { API_URL } from "../util/util";
import { Button } from "react-bootstrap";
import { showMessage } from "../util/util";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../images/icons/GETCAR_COLOR.png";
import { PDFDocument } from "pdf-lib";

import Modal from "react-modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Importa los estilos CSS necesarios
import { isSameDay } from "date-fns";
import { parse } from "date-fns";

import "./ServiciosTransporte.css";


const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    maxHeight: "80%",
    overflow: "auto",
    zIndex: 9999,
  },
};

const ServiciosTransporte = () => {
  const [clientId, setClientId] = useState(null); // Estado para mantener el ID del cliente
  const [driverId, setDriverId] = useState(null);
  const [searchServiceId, setSearchServiceId] = useState("");
  const [formData, setFormData] = useState({
    NombreEmpresa: "",
    NombreTransportador: "",
    NumCedula: "",
    Despacho: "",
    HoraInicio: "",
    HoraDespacho: "",
    HoraFinal: "",
    DescripcionServicios: "",
    Factura: "",
    devoluciones: "",
    observacionDevolucion: "", // Nuevo campo para observaciones de devolución
    vehiculoRefrigerado: "",
    albaran: "",
    Tipodeservicio: "",
    Liquidacion: "",
    NombreAsesor: "",
    nombreReceptor: "",
  });
  const [clientForms, setClientForms] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [eventData, setEventData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showObservationModal, setShowObservationModal] = useState(false);
  const [selectedObservation, setSelectedObservation] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFacturas, setSelectedFacturas] = useState([]);
  const [selectedFotoReceptor, setSelectedFotoReceptor] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [adjuntarSoporte, setAdjuntarSoporte] = useState(false);
  const [showCompleteLegalizationButton, setShowCompleteLegalizationButton] =
    useState(false);
  const [showCompleteLegalizationModal, setShowCompleteLegalizationModal] =
    useState(false);
  const [selectedIncompleteLegalization, setSelectedIncompleteLegalization] =
    useState(null);

  const authData = localStorage.getItem("authData");

  useEffect(() => {
    if (!authData) {
      navigate("/");
      showMessage(
        "Error",
        "Para acceder a los formularios, inicia sesión",
        "error",
        "Reintentar"
      );
    } else {
      const parsedAuthData = JSON.parse(authData);
      setDriverId(parsedAuthData.id);
      fetchClientForms(parsedAuthData.id);
      fetchAllClientForms(parsedAuthData.id);

      // Nueva solicitud Fetch para obtener datos del cliente
      fetch(API_URL + `client/${parsedAuthData.id}/full-info`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Error al obtener datos del cliente");
          }
        })
        .then((data) => {
          // Actualiza el estado formData con los datos del cliente
          setFormData({
            ...formData,
            NombreTransportador: data.name, // Ajusta esto según la estructura de tus datos
            NumCedula: data.identification, // Ajusta esto según la estructura de tus datos
            // Agrega más campos según sea necesario
          });
          setClientData(data); // Almacena los datos del cliente en el estado
        })
        .catch((error) => {
          console.error("Error al obtener datos del cliente:", error);
        });
    }
  }, []);

  const fetchAllClientForms = async (clientId) => {
    try {
      Swal.fire({
        title: "Cargando",
        html: '<div class="loader"></div><p>Cargando tus datos  ...</p>',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await fetch(API_URL + `guide/drivers/${clientId}/forms`);
      if (response.ok) {
        const forms = await response.json();
        const events = forms.map((form) => ({
          date: parse(form.date, "dd/MM/yyyy HH:mm:ss", new Date()), // Ajusta cómo acceder a la fecha en tus datos
          Id: form.id,
          NombreEmpresa: form.nombreEmpresa,
          NombreServicio: form.nombreServicio,
          NombreTransportador: form.nombreTransportador,
          NumCedula: form.numCedula, // Ajusta cómo acceder a la descripción en tus datos
          DescripcionServicios: form.descripcionServicios, // Ajusta cómo acceder a la descripción en tus datos
          Despacho: form.despacho, // Ajusta cómo acceder a la descripción en tus datos
          HoraInicio: form.horaInicio, // Ajusta cómo acceder a la descripción en tus datos
          HoraDespacho: form.horaDespacho, // Ajusta cómo acceder a la descripción en tus datos
          HoraFinal: form.horaFinal, // Ajusta cómo acceder a la descripción en tus datos
          Factura: form.factura, // Ajusta cómo acceder a la descripción en tus datos
          devoluciones: form.devoluciones, // Ajusta cómo acceder a la descripción en tus datos
          vehiculoRefrigerado: form.vehiculoRefrigerado, // Ajusta cómo acceder a la descripción en tus datos
          albaran: form.albaran, // Ajusta cómo acceder a la descripción en tus datos
          Tipodeservicio: form.tipodeservicio, // Ajusta cómo acceder a la descripción en tus datos
          Liquidacion: form.liquidacion, // Ajusta cómo acceder a la descripción en tus datos
          NombreAsesor: form.nombreAsesor, // Ajusta cómo acceder a la descripción en tus datos
          nombreReceptor: form.nombreReceptor, // Ajusta cómo acceder a la descripción en tus datos
          fotoReceptorFile: form.fotoReceptorFile,
        }));

        // Verifica si alguno de los eventos tiene fotoReceptorFile nulo
        const hasServiceWithNullFotoReceptor = events.some(
          (event) => event.fotoReceptorFile === null
        );

        // Actualiza el estado para mostrar el botón "Completar Legalización"
        setShowCompleteLegalizationButton(hasServiceWithNullFotoReceptor);

        setEventData(events);
        Swal.close();
      } else {
        console.error("Error al obtener los formularios");
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error al obtener los formularios",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.error("Error al obtener los formularios:", error);
      Swal.fire({
        title: "Error",
        text:
          "Ha ocurrido un error al obtener los formularios: " + error.message,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const fetchClientForms = async () => {
    try {
      const response = await fetch(API_URL + "service/clients");
      if (response.ok) {
        const forms = await response.json();
        setClientForms(forms);
      } else {
        console.error("Error al obtener los formularios");
      }
    } catch (error) {
      console.error("Error al obtener los formularios:", error);
    }
  };

  const handleSearchService = () => {
    // Obtén el servicio correspondiente en función del ID ingresado
    const service = clientForms.find((form) => form.id === searchServiceId);

    if (service) {
      // Verifica si el servicio ya ha sido legalizado
      if (service.completado) {
        // Muestra un mensaje de que el servicio ya ha sido legalizado
        showMessage(
          "Advertencia",
          "Este servicio ya ha sido legalizado.",
          "error",
          "Aceptar"
        );
      } else {
        // Llena los campos del formulario con los datos del servicio
        setFormData({
          ...formData,
          NombreEmpresa: service.empresaCliente,
          NombreServicio: service.nombreServicio,
          HoraInicio: service.horaInicio,
          HoraFinal: service.horaFinal,
          DescripcionServicios: service.observaciones,
          vehiculoRefrigerado: service.vehiculoRefrigerado,
          Tipodeservicio: service.tipoServicio,
          NombreAsesor: service.nombreVendedor,
          // Agrega otros campos según sea necesario
        });

        setClientId(service.clientId);
        setSelectedServiceId(service.id);
      }
    } else {
      // Maneja el caso en que el ID no coincide con ningún servicio
      alert("No se encontró ningún servicio con el ID ingresado.");
    }
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const handleToggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleOpenObservationModal = (observation) => {
    setSelectedObservation(observation);
    setShowObservationModal(true);
  };

  let navigate = useNavigate();

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);
  };

  const handleSubmitCompleteLegalization = async (event) => {
    event.preventDefault();

    // Verifica si se ha seleccionado una legalización para completar
    if (!selectedIncompleteLegalization) {
      // Muestra un mensaje de error si no se selecciona una legalización
      showMessage(
        "Error",
        "Por favor, selecciona una legalización para completar.",
        "error",
        "Aceptar"
      );
      return;
    }

    // Prepara los datos a enviar para completar la legalización
    const formDataToSend = new FormData();

    // Convierte las imágenes seleccionadas a un archivo PDF
    const fotoReceptorPDF = await convertFotoReceptorToPDF(selectedFiles);

    if (fotoReceptorPDF) {
      formDataToSend.append(
        "fotoReceptor",
        fotoReceptorPDF,
        "fotoReceptor.pdf"
      );
    }

    try {
      // Realiza una solicitud API para completar la legalización del servicio seleccionado
      const response = await fetch(
        API_URL + `guide/guide/${selectedIncompleteLegalization}/photo`,
        {
          method: "POST", // o "PUT" o "PATCH" según sea necesario
          body: formDataToSend, // Incluye el objeto FormData con la foto adjunta y el ID
          // Incluye cualquier encabezado necesario, como 'Content-Type' si no se configura automáticamente
        }
      );

      if (response.ok) {
        // Maneja el éxito, muestra un mensaje de éxito y realiza cualquier acción necesaria
        showMessage(
          "Éxito",
          "La legalización se ha completado con éxito.",
          "success",
          "Aceptar"
        );

        // También puedes realizar una acción para actualizar la lista de servicios en el menú desplegable, si es necesario.
      } else {
        // Maneja los errores, muestra un mensaje de error y realiza el manejo de errores
        showMessage(
          "Error",
          "Ha ocurrido un error al completar la legalización. Por favor, inténtalo de nuevo.",
          "error",
          "Aceptar"
        );
      }
      // Recarga la página después de 2 segundos (o el tiempo que desees)
      setTimeout(() => {
        window.location.reload();
      }, 2000); // 2000 milisegundos = 2 segundosk
    } catch (error) {
      console.error("Error al completar la legalización:", error);

      // Muestra un mensaje de error en caso de error de conexión u otros errores
      showMessage(
        "Error",
        "Ha ocurrido un error al completar la legalización. Por favor, verifica tu conexión e inténtalo de nuevo.",
        "error",
        "Aceptar"
      );
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "file") {
      const file = event.target.files[0];
      setFormData((prevFormData) => ({ ...prevFormData, [name]: file }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  // Función para convertir facturas a PDF
  const convertFacturasToPDF = async (facturaFiles) => {
    const pdfDoc = await PDFDocument.create();
    for (const facturaFile of facturaFiles) {
      const page = pdfDoc.addPage([612, 792]); // Tamaño de la página en puntos

      const imageBytes = await fetch(URL.createObjectURL(facturaFile)).then(
        (res) => res.arrayBuffer()
      );

      const image = await pdfDoc.embedJpg(imageBytes);

      // Obtén las dimensiones de la imagen
      const { width, height } = image.scale(1); // Sin escalar (tamaño original)

      // Ajusta el tamaño de la imagen para que llene toda la página
      page.drawImage(image, {
        x: 0,
        y: 0, // Coloca la imagen en la esquina superior izquierda
        width: 612, // Ancho de la página
        height: 792, // Alto de la página
      });
    }

    // Convierte el PDF generado a un Blob
    const facturasBlob = new Blob([new Uint8Array(await pdfDoc.save())], {
      type: "application/pdf",
    });

    return facturasBlob;
  };

  // Función para convertir foto del receptor a PDF
  const convertFotoReceptorToPDF = async (fotoReceptorFiles) => {
    if (fotoReceptorFiles.length === 0) {
      return null;
    }

    const pdfDoc = await PDFDocument.create();
    for (const fotoReceptorFile of fotoReceptorFiles) {
      const page = pdfDoc.addPage([612, 792]); // Tamaño de la página en puntos

      const imageBytes = await fetch(
        URL.createObjectURL(fotoReceptorFile)
      ).then((res) => res.arrayBuffer());

      const image = await pdfDoc.embedJpg(imageBytes);

      // Obtén las dimensiones de la imagen
      const { width, height } = image.scale(1); // Sin escalar (tamaño original)

      // Ajusta el tamaño de la imagen para que llene toda la página
      page.drawImage(image, {
        x: 0,
        y: 0,
        width: 612,
        height: 792,
      });
    }

    // Convierte el PDF generado a un Blob
    const fotoReceptorBlob = new Blob([new Uint8Array(await pdfDoc.save())], {
      type: "application/pdf",
    });

    return fotoReceptorBlob;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();

      // Agregar el ID del cliente al formData
      formDataToSend.append("clientId", clientId);
      formDataToSend.append("driverId", driverId);

      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      // Convertir y adjuntar las facturas como un archivo PDF
      const facturasPDF = await convertFacturasToPDF(selectedFacturas);
      if (facturasPDF) {
        formDataToSend.append("facturas", facturasPDF, "facturas.pdf");
      }

      // Convertir y adjuntar la foto del receptor como un archivo PDF
      const fotoReceptorPDF = await convertFotoReceptorToPDF(
        selectedFotoReceptor
      );
      if (fotoReceptorPDF) {
        formDataToSend.append(
          "fotoReceptor",
          fotoReceptorPDF,
          "fotoReceptor.pdf"
        );
      }

      try {
        Swal.fire({
          title: "Cargando",
          html: '<div class="loader"></div><p>Enviando formulario...</p>',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(API_URL + "guide/guide", {
          method: "POST",
          body: formDataToSend,
        });

        Swal.close();

        if (response.ok) {
          showMessage(
            "success!",
            "Contrato enviado. Espere que nos comuniquemos con usted.",
            "success",
            "Continuar"
          );

          // Verifica si hay un servicio seleccionado y completa el viaje correspondiente
          if (selectedServiceId) {
            try {
              // Hacer una solicitud al servidor para completar el viaje
              const completarViajeResponse = await fetch(
                API_URL + `service/${selectedServiceId}/completar`,
                {
                  method: "PUT",
                }
              );

              if (completarViajeResponse.ok) {
                // El viaje se completó con éxito
                // Puedes realizar cualquier acción adicional que desees, como mostrar un mensaje de éxito o redirigir al usuario a otra página
              } else {
                // Manejar errores si la solicitud al servidor falla
                console.error("Error al completar el viaje");
              }
            } catch (error) {
              console.error("Error al completar el viaje:", error);
            }
          }
          // Recarga la página después de 2 segundos (o el tiempo que desees)
          setTimeout(() => {
            window.location.reload();
          }, 2000); // 2000 milisegundos = 2 segundosk
        } else {
          showMessage(
            "Error",
            "Error al enviar el formulario",
            "error",
            "Aceptar"
          );
        }
      } catch (error) {
        console.error("Error al enviar el formulario", error);
        showMessage(
          "Error",
          "Error al enviar el formulario: " + error.message,
          "error",
          "Aceptar"
        );
      }
    } catch (error) {
      console.error("Error al procesar el formulario", error);
    }
  };

  return (
    <div>
      <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Servicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Guía servicio de transporte</h1>
          </div>
        </nav>
      </header>

      <div className="container-service">
        <Button onClick={handleToggleModal}>Legalizar Servicio</Button>
        {showCompleteLegalizationButton && (
          <Button onClick={() => setShowCompleteLegalizationModal(true)}>
            Completar Legalización
          </Button>
        )}

        <Button onClick={handleToggleCalendar}>Mostrar Calendario</Button>
        <Modal
          isOpen={showModal}
          onRequestClose={handleToggleModal}
          style={modalStyles}
        >
          <h2>legalizacion de servicios</h2>
          <h4>
            Seleccione un servicio legalizado para autocompletar los espacios de
            lo contrario complete el formulario
          </h4>

          <input
            type="text"
            id="searchServiceId"
            name="searchServiceId"
            placeholder="Buscar por ID de servicio"
            value={searchServiceId}
            onChange={(e) => setSearchServiceId(e.target.value)}
          />
          <button onClick={handleSearchService}>Buscar</button>

          <form onSubmit={handleSubmit} className="custom-form">
            <input
              type="text"
              id="NombreEmpresa"
              name="NombreEmpresa"
              placeholder="Nombre empresa"
              value={formData.NombreEmpresa}
              onChange={handleInputChange}
            />
            <input
              type="text"
              id="NombreServicio"
              name="NombreServicio"
              placeholder="Nombre Servicio "
              value={formData.NombreServicio}
              onChange={handleInputChange}
            />
            <input
              type="text"
              id="NombreTransportador"
              name="NombreTransportador"
              placeholder="Nombre transportador"
              value={formData.NombreTransportador}
              onChange={handleInputChange}
            />
            <input
              type="text"
              id="NumCedula"
              name="NumCedula"
              placeholder="Numero de cedula"
              value={formData.NumCedula}
              onChange={handleInputChange}
            />
            <input
              type="text"
              id="Despacho"
              name="Despacho"
              placeholder="Despachado por"
              value={formData.Despacho}
              onChange={handleInputChange}
            />
            <p>Hora inicio</p>
            <input
              type="time"
              id="HoraInicio"
              name="HoraInicio"
              value={formData.HoraInicio}
              onChange={handleInputChange}
            />
            <p>Hora despacho</p>
            <input
              type="time"
              id="HoraDespacho"
              name="HoraDespacho"
              value={formData.HoraDespacho}
              onChange={handleInputChange}
            />
            <p>Hora final</p>
            <input
              type="time"
              id="HoraFinal"
              name="HoraFinal"
              value={formData.HoraFinal}
              onChange={handleInputChange}
            />
            <input
              type="textarea"
              id="DescripcionServicios"
              name="DescripcionServicios"
              placeholder="Descripcion del servicio"
              value={formData.DescripcionServicios}
              onChange={handleInputChange}
            />
            <input
              type="textarea"
              id="Factura"
              name="Factura"
              placeholder="Numero de factura"
              value={formData.Factura}
              onChange={handleInputChange}
            />
            <select
              name="devoluciones"
              value={formData.devoluciones}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  devoluciones: e.target.value,
                  observacionDevolucion:
                    e.target.value === "Si"
                      ? ""
                      : formData.observacionDevolucion,
                });
              }}
            >
              <option value="">Indique si hubo devoluciones</option>
              <option>Si</option>
              <option>No</option>
            </select>

            {formData.devoluciones === "Si" && (
              <input
                type="text"
                id="observacionDevolucion"
                name="observacionDevolucion"
                placeholder="Observación de Devolución"
                value={formData.observacionDevolucion}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    observacionDevolucion: e.target.value,
                  })
                }
              />
            )}
            <select
              name="vehiculoRefrigerado"
              value={formData.vehiculoRefrigerado}
              onChange={handleInputChange}
            >
              <option value="">Requiere vehículo refrigerado</option>
              <option>No</option>
              <option>Si</option>
            </select>
            <p>Fecha de entrega albaran</p>
            <input
              type="date"
              id="albaran"
              name="albaran"
              value={formData.albaran}
              onChange={handleInputChange}
            />
            <select
              name="Tipodeservicio"
              value={formData.Tipodeservicio}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar tipo de servicio</option>
              <option>Turno Completo</option>
              <option>Medio turno</option>
              <option>Servicio rapido</option>
              <option>Otro </option>
            </select>
            <input
              type="text"
              id="Liquidacion"
              name="Liquidacion"
              placeholder="Liquidacion"
              value={formData.Liquidacion}
              onChange={handleInputChange}
            />
            <select
              name="NombreAsesor"
              value={formData.NombreAsesor}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar nombre del asesor</option>
              <option>Jorge Moreno</option>
              <option>Edgar Garcia </option>
              <option>Nicolas Lopez</option>
              <option>No tengo vendedor asignado </option>
            </select>
            <input
              type="text"
              id="nombreReceptor"
              name="nombreReceptor"
              placeholder="Nombre del receptor"
              value={formData.nombreReceptor}
              onChange={handleInputChange}
            />
            <input
              type="textarea"
              id="Observaciones"
              name="Observaciones"
              placeholder="Observaciones"
              value={formData.Observaciones}
              onChange={handleInputChange}
            />
            <p>
              {" "}
              Puede adjuntar todas las fotos de las facturas. Por favor, adjunte
              solo archivos de imagen.
            </p>
            <label for="facturas">Adjuntar facturas </label>
            <input
              type="file"
              id="facturas"
              name="facturas"
              accept=".jpg,.jpeg,"
              onChange={(e) => setSelectedFacturas(Array.from(e.target.files))}
              multiple // Permite seleccionar múltiples archivos
              max-size="20971520"
            />
            <div class="checkbox-container">
              <input
                type="checkbox"
                id="adjuntarSoporteCheckbox"
                name="adjuntarSoporteCheckbox"
                checked={adjuntarSoporte}
                onChange={(e) => {
                  setAdjuntarSoporte(e.target.checked);

                  if (e.target.checked) {
                    showMessage(
                      "Información",
                      "Cuando se encuentre en el lugar de entrega, adjunte las evidencias para que el servicio quede completamente legalizado.",
                      "info",
                      "Aceptar"
                    );
                  }
                }}
              />
              <label htmlFor="adjuntarSoporteCheckbox">
                Adjuntar la foto del receptor- recuerda que lo puedes hacer
                despues si no te encuentras ahora mismo en el lugar
              </label>
            </div>
            <label htmlFor="fotoReceptor">Foto del receptor</label>
            <input
              type="file"
              id="fotoReceptor"
              name="fotoReceptor"
              accept=".jpg,.jpeg,"
              onChange={(e) =>
                setSelectedFotoReceptor(Array.from(e.target.files))
              }
              multiple // Permite seleccionar múltiples archivos
              disabled={!adjuntarSoporte} // Deshabilita si adjuntarSoporte es falso
              max-size="20971520"
            />

            <div className="button-group">
              <button type="submit" className="submit-button">
                Guardar
              </button>
              <button onClick={handleToggleModal} className="cancel-button">
                Cancelar
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={showCompleteLegalizationModal}
          onRequestClose={handleToggleModal}
          style={modalStyles}
        >
          <h2>Completar legalizacion </h2>
          <select
            value={selectedIncompleteLegalization}
            onChange={(e) => setSelectedIncompleteLegalization(e.target.value)}
          >
            <option value="">Selecciona una legalización incompleta</option>
            {eventData
              .filter((event) => !event.fotoReceptorFile) // Filtra las legalizaciones sin fotoReceptorFile
              .map((event) => (
                <option key={event.Id} value={event.Id}>
                  {event.NombreEmpresa} - {event.NombreServicio}-{event.DescripcionServicios}{" "}
                  {/* Ajusta según tu estructura de datos */}
                </option>
              ))}
          </select>

          <form
            onSubmit={handleSubmitCompleteLegalization}
            className="custom-form"
          >
            <label htmlFor="fotoReceptor">
              Foto del receptor (solo imágenes JPG y PNG)
            </label>
            <input
              type="file"
              id="fotoReceptor"
              name="fotoReceptor"
              accept=".jpg,.jpeg"
              multiple // Permite seleccionar múltiples archivos
              onChange={handleFileChange}
              max-size="20971520"
            />

            <div className="button-group">
              <Button type="submit" className="submit-button">
                Guardar
              </Button>
              <Button onClick={() => setShowCompleteLegalizationModal(false)}>
                Cancelar
              </Button>
            </div>
          </form>
        </Modal>

        {showCalendar && (
          <div className="calendar-container">
            <Calendar
              tileContent={({ date, view }) => {
                if (view === "month") {
                  const eventsOnDay = eventData.filter((event) =>
                    isSameDay(event.date, date)
                  );

                  if (eventsOnDay.length > 0) {
                    return (
                      <div>
                        {eventsOnDay.map((event, index) => (
                          <button
                            key={index}
                            className="observation-button yellow-bg"
                            onClick={() => handleOpenObservationModal(event)}
                          >
                            {event.NombreEmpresa}
                          </button>
                        ))}
                      </div>
                    );
                  }
                }
                return null;
              }}
            />
          </div>
        )}
        {showObservationModal && (
          <Modal
            isOpen={showObservationModal}
            onRequestClose={() => setShowObservationModal(false)}
            style={modalStyles}
          >
            <h2>Datos</h2>
            <p>NombreEmpresa: {selectedObservation.NombreEmpresa}</p>

            <p>
              Nombre del Transportador:{" "}
              {selectedObservation.NombreTransportador}
            </p>
            <p>
              Descripcion del servicio:{" "}
              {selectedObservation.DescripcionServicios}
            </p>
            <p>Perosna que despacho: {selectedObservation.Despacho}</p>
            <p>Hora inicio: {selectedObservation.HoraInicio}</p>
            <p>Hora despacho: {selectedObservation.HoraDespacho}</p>
            <p>Hora final: {selectedObservation.HoraFinal}</p>
            <p>Numero de factura: {selectedObservation.Factura}</p>
            <p>Hubo devoluciones: {selectedObservation.devoluciones}</p>
            <p>
              Requirio vehiculo refrigerado:{" "}
              {selectedObservation.vehiculoRefrigerado}
            </p>
            <p>Fecha de albaran: {selectedObservation.albaran}</p>
            <p>Tipo de servicio: {selectedObservation.Tipodeservicio}</p>
            <p>Liquidacion: {selectedObservation.Liquidacion}</p>
            <p>Nombre del asesor: {selectedObservation.NombreAsesor}</p>
            <p>Nombre del receptor: {selectedObservation.nombreReceptor}</p>

            <Button onClick={() => setShowObservationModal(false)}>
              Cerrar
            </Button>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ServiciosTransporte;
