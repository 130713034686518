import React, { useState, useEffect } from "react";
import { API_URL } from "../util/util";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const SolicitudServicioForm = () => {
  const [approvedClients, setApprovedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(""); // Para almacenar el cliente seleccionado
  const [clientId, setClientId] = useState("");

  // Obtener la lista de clientes aprobados cuando se carga el componente
  useEffect(() => {
    fetch(API_URL + "client/approved")
      .then((response) => response.json())
      .then((data) => {
        setApprovedClients(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const selectedClientData = approvedClients.find(
      (client) => client.id === selectedClient
    );
    if (selectedClientData) {
      setClientId(selectedClientData.id);
      setFormData({
        ...formData,
        EmpresaCliente:
          selectedClientData.name + " " + selectedClientData.lastName,
        NitCedula: selectedClientData.identification,
        Direccion: selectedClientData.address,
        Telefono: selectedClientData.phone,
      });
    } else {
      setClientId("");
      setFormData({
        ...formData,
        // Restablecer los campos del formulario a sus valores iniciales
        NombreServicio: "",
        EmpresaCliente: "",
        NitCedula: "",
        Direccion: "",
        Telefono: "",
      });
    }
  }, [selectedClient, approvedClients]);

  const [formData, setFormData] = useState({
    NombreServicio: "",
    EmpresaCliente: "",
    NitCedula: "",
    Direccion: "",
    Telefono: "",
    Ciudad: "",
    NombreVendedor: "",
    Servicio: "",
    vehiculoRefrigerado: "",
    TipoServicio: "",
    HoraInicio: "",
    HoraFinal: "",
    NombreConductor: "",
    NumCedula: "",
    Celular: "",
    DireccionVehiculo: "",
    PesoCarga: "",
    observaciones: "",
    Pago: "",
  });

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    setFormData({
      ...formData,
      [name]: type === "file" ? event.target.files[0] : value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();

      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      formDataToSend.append("clientId", clientId); // Agrega el clientId al FormData

      const response = await fetch(API_URL + "service/service", {
        method: "POST",
        body: formDataToSend, // Envía el FormData con todos los datos del formulario
      });

      if (response.ok) {
        Swal.fire(
          "¡Solicitud exitosa!",
          "Su solicitud ha sido enviada.",
          "success"
        );
        // Puedes redirigir al usuario a una página de confirmación o realizar otra acción aquí
      } else {
        Swal.fire(
          "Error",
          "Error al enviar el formulario de solicitud.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error al procesar el formulario", error);
    }
  };

  return (
    <div>
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>Solicitud de Servicio</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit} className="custom-form">
            <div>
              <label htmlFor="clientSelect">Seleccionar Cliente:</label>
              <select
                id="clientSelect"
                name="clientSelect"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                <option value="">Seleccionar un cliente</option>
                {approvedClients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name} {client.lastName}
                  </option>
                ))}
              </select>
            </div>
            <input
              type="text"
              name="NombreServicio"
              placeholder="Nombre del Servicio"
              value={formData.NombreServicio}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="EmpresaCliente"
              placeholder="Nombre de la Empresa o Cliente"
              value={formData.EmpresaCliente}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="NitCedula"
              placeholder="# de NIT o Cédula"
              value={formData.NitCedula}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="Direccion"
              placeholder="Dirección"
              value={formData.Direccion}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="Telefono"
              placeholder="# de Teléfono"
              value={formData.Telefono}
              onChange={handleInputChange}
            />
                        <p>Ingrese la fecha y hora del servicio</p>
            <input
              type="datetime-local"
              name="FechaServicio"
              value={formData.FechaServicio || ""}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="Ciudad"
              placeholder="Ciudad"
              value={formData.Ciudad}
              onChange={handleInputChange}
          />
              <select
              name="NombreVendedor"
              value={formData.NombreVendedor}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar nombre del asesor</option>
              <option>Jorge Moreno</option>
              <option>Edgar Garcia </option>
              <option>Nicolas Lopez</option>
              <option>No tengo vendedor asignado </option>
            </select>
            <select
              name="Servicio"
              value={formData.Servicio}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar servicio</option>
              <option>Sencillo</option>
              <option>Mini van</option>
              <option>Motorizados</option>
              <option>Envio nacional o paqueteo</option>
            </select>
            <select
              name="vehiculoRefrigerado"
              value={formData.vehiculoRefrigerado}
              onChange={handleInputChange}
            >
              <option value="">Requiere vehículo refrigerado</option>
              <option>Si</option>
              <option>No</option>
            </select>
            <select
              name="TipoServicio"
              value={formData.TipoServicio}
              onChange={handleInputChange}
            >
              <option value="">Tipo de Servicio</option>
              <option>Turno Completo</option>
              <option>Medio turno</option>
              <option>Servicio rápido</option>
              <option>Servicio Especial</option>
            </select>
            <input
              type="time"
              name="HoraInicio"
              value={formData.HoraInicio}
              onChange={handleInputChange}
            />
            <input
              type="time"
              name="HoraFinal"
              value={formData.HoraFinal}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="NombreConductor"
              placeholder="Ingresa el nombre del contacto responsable"
              value={formData.NombreConductor}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="NumCedula"
              placeholder="# de Cédula  "
              value={formData.NumCedula}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="Celular"
              placeholder="# de Celular  "
              value={formData.Celular}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="DireccionVehiculo"
              placeholder="Dirección de Recogida"
              value={formData.DireccionVehiculo}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="PesoCarga"
              placeholder="Peso de la Carga (en kilos)"
              value={formData.PesoCarga}
              onChange={handleInputChange}
            />
            <textarea
              name="observaciones"
              placeholder="Observaciones"
              value={formData.observaciones}
              onChange={handleInputChange}
            />
            <select
              name="Pago"
              value={formData.Pago}
              onChange={handleInputChange}
            >
              <option value="">Tipo de Pago</option>
              <option>Credito</option>
              <option>De contado</option>
            </select>
            <Button type="submit">Enviar Solicitud</Button>
          </form>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
};

export default SolicitudServicioForm;
