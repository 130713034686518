import React from "react";
import Construccion from "../images/pagina-en-construccion.png.jpg"

import "./Construccion.css"; // Archivo CSS para los estilos

const PestanaEnConstruccion = () => {
  return (
    <div className="construccion-container">
      <h1>Bajo construcción</h1>
      <img src={Construccion} alt="Construcción" />
      <p>Nuestro sitio web está en construcción,</p>
      <p>pero estamos emocionados por sorprenderte pronto con contenido increíble.</p> 
      <p>¡Estamos trabajando arduamente para crear una experiencia excepcional para ti!</p>
    </div>
  );
};

export default PestanaEnConstruccion;

