import React from "react";
import { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { API_URL } from "../util/util";
import { Button } from "react-bootstrap";
import { showMessage } from "../util/util";
import { FaTruck } from "react-icons/fa";

import logo from "../images/icons/GETCAR_COLOR.png";
import Swal from "sweetalert2";

import "./FormularioCliente.css";

const FormularioCliente = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [progress, setProgress] = useState(0);

  const handleInputChange = (event) => {
    const { name, type } = event.target;

    if (type === "file") {
      const file = event.target.files[0];
      const maxSize = 2 * 1024 * 1024; // Tamaño máximo en bytes (2 MB)

      if (file && file.size > maxSize) {
        alert(
          "El archivo seleccionado excede el tamaño máximo permitido de 2 MB."
        );
        // Restablecer el valor del input de archivo si es necesario
        event.target.value = null;
        return;
      }

      setFormData((prevFormData) => ({ ...prevFormData, [name]: file }));
    } else {
      const value = event.target.value;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Realizar validaciones antes de enviar el formulario
    const requiredFields = [
      "NombreCliente",
      "NitCedula",
      "TelefonoCliente",
      "Ciudad",
      "Dirrecion",
      "SitiosWeb",
      "AñoFundacion",
      "NombreAsesor",
      "Servicio",
      "vehiculoRefrigerado",
      "CedulaEmpresa",
      "RutEmpresa",
      "CamaraYComercio",
      "TipoEmpresa",
      "TipoProducto",
      "FrecuenciaViajes",
      "ZonasGeograficas",
      "presupuesto",
      "desafios",
      "NombresYapellidos",
      "NumCedulaDatos",
      "FechaCedulaDatos",
      "LugarCedulaDatos",
      "Celular",
      "Fijo",
      "Correo",
      "Direccion",
      "CiudadContacto",
      "Cargo",
      "Cedula",
      "NombresYapellidosauxiliar",
      "NumCedulaDatosauxiliar",
      "FechaCedulaDatosauxiliar",
      "LugarCedulaDatosauxiliar",
      "Celularauxiliar",
      "Fijoauxiliar",
      "Correoauxiliar",
      "Direccionauxiliar",
      "Ciudadauxiliar",
      "Cargoauxiliar",
      "Cedulaauxiliar",
      "NombresYapellidosContable",
      "NumCedulaDatosContable",
      "FechaCedulaDatosContable",
      "LugarCedulaDatosContable",
      "CelularContable",
      "FijoContable",
      "CorreoContable",
      "DireccionContable",
      "CiudadContable",
      "CargoContable",
      "CedulaContable",
    ];

    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      const errorMessage = `Los siguientes campos son obligatorios: ${missingFields.join(
        ", "
      )}`;
      showMessage("Error", errorMessage, "error", "Aceptar");
      return;
    }
    try {
      const formDataToSend = new FormData();

      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      try {
        Swal.fire({
          title: "Cargando",
          html: '<div class="loader"></div><p>Enviando formulario...</p>',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(API_URL + "register/register", {
          method: "POST",
          body: formDataToSend,
        });

        Swal.close();

        if (response.ok) {
          showMessage(
            "success!",
            "Formulario enviado. Espere que nos comuniquemos con usted.",
            "success",
            "Continuar"
          );
        } else {
          showMessage(
            "Error",
            "Error al enviar el formulario. Por favor, verifica que hayas subido todos los archivos adjuntos y completado todos los campos del formulario.",
            "error",
            "Aceptar"
          );
        }
      } catch (error) {
        console.error("Error al enviar el formulario", error);
        showMessage(
          "Error",
          "Error al enviar el formulario: " + error.message,
          "error",
          "Aceptar"
        );
      }
    } catch (error) {
      console.error("Error al procesar el formulario", error);
    }
  };
  const ProgressBarWithIcon = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [progress, setProgress] = useState(20);
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
    setProgress(step * 20);
  };

  const handleNext = () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    }
    setProgress((currentStep + 1) * 20);
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
    setProgress((currentStep - 1) * 20);
  };

  const getCartIconPosition = () => {
    const positionPercentage =
      progress === 0 ? 0 : ((progress - 20) / 80) * 100;
    return `${positionPercentage}%`;
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <fieldset>
            <legend>Usuario</legend>
            <div className="Datosdelvehiculo">
              <div>
                <label htmlFor="NombreCliente"></label>
                <input
                  type="text"
                  id="NombreCliente"
                  name="NombreCliente"
                  placeholder="Nombre Empresa o cliente "
                  value={formData.NombreCliente || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="NitCedula"></label>
                <input
                  type="text"
                  id="NitCedula"
                  name="NitCedula"
                  placeholder="Nit o numero de Cedula "
                  value={formData.NitCedula || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="TelefonoCliente"></label>
                <input
                  type="number"
                  id="TelefonoCliente"
                  name="TelefonoCliente"
                  placeholder=" # Telefono "
                  value={formData.TelefonoCliente || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="Ciudad"></label>
                <input
                  type="text"
                  id="Ciudad"
                  name="Ciudad"
                  placeholder="Ingresa la Ciudad"
                  value={formData.Ciudad || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Dirrecion"></label>
                <input
                  type="text"
                  id="Dirrecion"
                  name="Dirrecion"
                  placeholder="Ingresa Dirrecion"
                  value={formData.Dirrecion || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="SitiosWeb"></label>
                <input
                  type="url"
                  id="SitiosWeb"
                  name="SitiosWeb"
                  placeholder="Ingresa tus SitiosWeb"
                  value={formData.SitiosWeb || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="AñoFundacion"></label>
                <p>Año fundacion de la empresa</p>
                <input
                  type="date"
                  id="AñoFundacion"
                  name="AñoFundacion"
                  value={formData.AñoFundacion || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="NombreAsesor"></label>
                <h4>Seleccione el nombre del asesor </h4>
                <select
                  name="NombreAsesor"
                  value={formData.NombreAsesor || ""}
                  onChange={handleInputChange}
                >
                  <option></option>
                  <option>Jorge Moreno</option>
                  <option>Edgar Garcia </option>
                  <option>Nicolas Lopez</option>
                  <option>No tengo vendedor asignado </option>
                </select>
              </div>
              <h4>
                Selecione el tipo de servicio por el que más se encuentra
                interesado
              </h4>
              <div>
                <select
                  name="Servicio"
                  value={formData.Servicio || ""}
                  onChange={handleInputChange}
                >
                  <option></option>
                  <option>Camion llanta sencilla</option>
                  <option>Mini van</option>
                  <option>Motorizado</option>
                  <option>Envio nacional</option>
                </select>
              </div>
              <h4>Requiere vehiculo refrigerado</h4>
              <div>
                <select
                  name="vehiculoRefrigerado"
                  value={formData.vehiculoRefrigerado || ""}
                  onChange={handleInputChange}
                >
                  <option></option>
                  <option>Si</option>
                  <option>No</option>
                </select>
              </div>
              <div>
                <label htmlFor="CedulaEmpresa">Cédula</label>
                <p>Adjunte un archivo en formato PDF de la cédula</p>
                <input
                  type="file"
                  id="CedulaEmpresa"
                  name="CedulaEmpresa"
                  accept="application/pdf"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="RutEmpresa">RUT</label>
                <p>Adjunte un archivo en formato PDF del RUT de la empresa</p>
                <input
                  type="file"
                  id="RutEmpresa"
                  name="RutEmpresa"
                  accept="application/pdf"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="CamaraYComercio">Cámara y Comercio</label>
                <p>
                  Adjunte un archivo en formato PDF del certificado de Cámara y
                  Comercio
                </p>
                <input
                  type="file"
                  id="CamaraYComercio"
                  name="CamaraYComercio"
                  accept="application/pdf"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 2:
        return (
          <fieldset>
            <legend>DATOS DE LA EMPRESA o CLIENTE</legend>
            <div className="DatosDeEmpresaCliente">
              <div>
                <label htmlFor="TipoEmpresa">
                  Tipo de empresa y actividad economica
                </label>
                <input
                  type="textarea"
                  id="TipoEmpresa"
                  name="TipoEmpresa"
                  value={formData.TipoEmpresa || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="TipoProducto">
                  ¿Qué tipos de productos transporta?
                </label>
                <input
                  type="textarea"
                  id="TipoProducto"
                  name="TipoProducto"
                  value={formData.TipoProducto || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="FrecuenciaViajes">
                  ¿Cuál es la frecuencia de viajes que requiere a la semana?
                </label>
                <input
                  type="textarea"
                  id="FrecuenciaViajes"
                  name="FrecuenciaViajes"
                  value={formData.FrecuenciaViajes || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>

              <div>
                <label htmlFor="ZonasGeograficas ">
                  En que Zonas Geograficas necesita los servicios de
                  Transporte.(Si es en bogota especifique la localidad),(Si es a
                  nivel nacional especifique la Ciudad)
                </label>
                <input
                  type="textarea"
                  id="ZonasGeograficas"
                  name="ZonasGeograficas"
                  value={formData.ZonasGeograficas || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="presupuesto ">
                  ¿Cuál es el presupuesto destinado al transporte a diario?,
                  especifique el tipo de servicio que utiliza con más
                  frecuencia.{" "}
                </label>
                <input
                  type="textarea"
                  id="presupuesto"
                  name="presupuesto"
                  value={formData.presupuesto || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="desafios ">
                  ¿Cuales son las principales necesidades o desafios de la
                  empresa en cuanto al transporte y la logistica?
                </label>
                <input
                  type="textarea"
                  id="desafios"
                  name="desafios"
                  value={formData.desafios || ""}
                  onChange={handleInputChange}
                  maxlength="80"
                />
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 3:
        return (
          <fieldset>
            <legend>Datos de Contacto</legend>
            <div className="Datos">
              <div>
                <label htmlFor="NombresYapellidos"></label>
                <input
                  type="text"
                  id="NombresYapellidos"
                  name="NombresYapellidos"
                  placeholder="nombres y apellidos"
                  value={formData.NombresYapellidos || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="NumCedulaDatos"></label>
                <input
                  type="text"
                  id="NumCedulaDatos"
                  name="NumCedulaDatos"
                  placeholder="Numero de cedula"
                  value={formData.NumCedulaDatos || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="FechaCedulaDatos"></label>
                <p>Fecha de expedicion de la cedula </p>
                <input
                  type="date"
                  id="FechaCedulaDatos"
                  name="FechaCedulaDatos"
                  value={formData.FechaCedulaDatos || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="LugarCedulaDatos"></label>
                <input
                  type="text"
                  id="LugarCedulaDatos"
                  name="LugarCedulaDatos"
                  placeholder="Lugar expedicion de la cedula"
                  value={formData.LugarCedulaDatos || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Celular"></label>
                <input
                  type="text"
                  id="Celular"
                  name="Celular"
                  placeholder="# de Celular"
                  value={formData.Celular || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Fijo"></label>
                <input
                  type="text"
                  id="Fijo"
                  name="Fijo"
                  placeholder=" # de Telefono fijo"
                  value={formData.Fijo || ""}
                  onChange={handleInputChange}
                  maxlength="10"
                />
              </div>
              <div>
                <label htmlFor="Correo"></label>
                <input
                  type="email"
                  id="Correo"
                  name="Correo"
                  placeholder="Correo contacto"
                  value={formData.Correo || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="Direccion "></label>
                <input
                  type="text"
                  id="Direccion"
                  name="Direccion"
                  placeholder="Ingresa una dirrecion fisica"
                  value={formData.Direccion || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="CiudadContacto"></label>
                <input
                  type="text"
                  id="CiudadContacto"
                  name="CiudadContacto"
                  placeholder="Ciudad"
                  value={formData.CiudadContacto || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Cargo"></label>
                <input
                  type="text"
                  id="Cargo"
                  name="Cargo"
                  placeholder="Cargo"
                  value={formData.Cargo || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Cedula">Cédula</label>
                <p>Adjunte un archivo en formato PDF de la cédula</p>
                <input
                  type="file"
                  id="Cedula"
                  name="Cedula"
                  accept="application/pdf"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 4:
        const handleNoAplicaChange = (event) => {
          const { checked } = event.target;

          if (checked) {
            const noAplicaData = {
              NombresYapellidosauxiliar: "No aplica",
              NumCedulaDatosauxiliar: "No aplica",
              FechaCedulaDatosauxiliar: "No aplica",
              LugarCedulaDatosauxiliar: "No aplica",
              Celularauxiliar: "No aplica",
              Fijoauxiliar: "No aplica",
              Correoauxiliar: "No aplica",
              Direccionauxiliar: "No aplica",
              Ciudadauxiliar: "No aplica",
              Cargoauxiliar: "No aplica",
            };

            setFormData({ ...formData, ...noAplicaData });
          } else {
            // Restablecer los valores a vacío o null si es necesario
            // Ejemplo:
            const resetData = {
              NombresYapellidosauxiliar: "",
              NumCedulaDatosauxiliar: "",
              FechaCedulaDatosauxiliar: "",
              LugarCedulaDatosauxiliar: "",
              Celularauxiliar: "",
              Fijoauxiliar: "",
              Correoauxiliar: "",
              Direccionauxiliar: "",
              Ciudadauxiliar: "",
              Cargoauxiliar: "",
            };

            setFormData({ ...formData, ...resetData });
          }
        };
        return (
          <fieldset>
            <legend>Datos de contacto auxiliar </legend>
            <div className="Datosauxiliar">
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="noAplicaAuxiliar"
                    checked={
                      formData.NombresYapellidosauxiliar === "No aplica" &&
                      formData.NumCedulaDatosauxiliar === "No aplica" &&
                      formData.FechaCedulaDatosauxiliar === "No aplica" &&
                      formData.LugarCedulaDatosauxiliar === "No aplica" &&
                      formData.Celularauxiliar === "No aplica" &&
                      formData.Fijoauxiliar === "No aplica" &&
                      formData.Correoauxiliar === "No aplica" &&
                      formData.Direccionauxiliar === "No aplica" &&
                      formData.Ciudadauxiliar === "No aplica" &&
                      formData.Cargoauxiliar === "No aplica"
                    }
                    onChange={handleNoAplicaChange}
                  />
                  No aplica
                </label>
              </div>
              <div>
                <label htmlFor="NombresYapellidosauxiliar"></label>
                <input
                  type="text"
                  id="NombresYapellidosauxiliar"
                  name="NombresYapellidosauxiliar"
                  placeholder="Nombres y apellidos auxiliar"
                  value={formData.NombresYapellidosauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="NumCedulaDatosauxiliar"></label>
                <input
                  type="text"
                  id="NumCedulaDatosauxiliar"
                  name="NumCedulaDatosauxiliar"
                  placeholder="Numero de cedula del auxiliar"
                  value={formData.NumCedulaDatosauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="FechaCedulaDatosauxiliar"></label>
                <p>Fecha de expedicion de la cedula del auxiliar</p>
                <input
                  type="date"
                  id="FechaCedulaDatosauxiliar"
                  name="FechaCedulaDatosauxiliar"
                  value={formData.FechaCedulaDatosauxiliar || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="LugarCedulaDatosauxiliar"></label>
                <input
                  type="text"
                  id="LugarCedulaDatosauxiliar"
                  name="LugarCedulaDatosauxiliar"
                  placeholder="Lugar expedicion de la cedula del auxiliar"
                  value={formData.LugarCedulaDatosauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Celularauxiliar"></label>
                <input
                  type="text"
                  id="Celularauxiliar"
                  name="Celularauxiliar"
                  placeholder=" # de Celular"
                  value={formData.Celularauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Fijoauxiliar"> </label>
                <input
                  type="text"
                  id="Fijoauxiliar"
                  name="Fijoauxiliar"
                  placeholder="# de Telefono fijo"
                  value={formData.Fijoauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="10"
                />
              </div>
              <div>
                <label htmlFor="Correoauxiliar"></label>
                <input
                  type="email"
                  id="Correoauxiliar"
                  name="Correoauxiliar"
                  placeholder="Correo del auxiliar"
                  value={formData.Correoauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="Direccionauxiliar "></label>
                <input
                  type="text"
                  id="Direccionauxiliar"
                  name="Direccionauxiliar"
                  placeholder="Dirrecion fisica"
                  value={formData.Direccionauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="Ciudadauxiliar"></label>
                <input
                  type="text"
                  id="Ciudadauxiliar"
                  name="Ciudadauxiliar"
                  placeholder="Ciudad"
                  value={formData.Ciudadauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Cargoauxiliar"></label>
                <input
                  type="text"
                  id="Cargoauxiliar"
                  name="Cargoauxiliar"
                  placeholder="Cargo"
                  value={formData.Cargoauxiliar || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Cedulaauxiliar">Cédula</label>
                <p>
                  Adjunte un archivo en formato PDF de la cédula del auxiliar
                </p>
                <input
                  type="file"
                  id="Cedulaauxiliar"
                  name="Cedulaauxiliar"
                  accept="application/pdf"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 5:
        return (
          <fieldset>
            <legend>Datos área Contable</legend>
            <div className="DatosContable">
              <div>
                <label htmlFor="NombresYapellidosContable"></label>
                <input
                  type="text"
                  id="NombresYapellidosContable"
                  name="NombresYapellidosContable"
                  placeholder="Nombres y apellidos de la persona encargada"
                  value={formData.NombresYapellidosContable || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="NumCedulaDatosContable"></label>
                <input
                  type="text"
                  id="NumCedulaDatosContable"
                  name="NumCedulaDatosContable"
                  placeholder="Numero de cedula persona encargada"
                  value={formData.NumCedulaDatosContable || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="FechaCedulaDatosContable"></label>
                <p>Fecha de expedicion de la cedula de la pesona encargada</p>
                <input
                  type="date"
                  id="FechaCedulaDatosContable"
                  name="FechaCedulaDatosContable"
                  value={formData.FechaCedulaDatosContable || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="LugarCedulaDatosContable"></label>
                <input
                  type="text"
                  id="LugarCedulaDatosContable"
                  name="LugarCedulaDatosContable"
                  placeholder="Lugar expedicion de la cedula de la persona encargada"
                  value={formData.LugarCedulaDatosContable || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="CelularContable"></label>
                <input
                  type="text"
                  id="CelularContable"
                  name="CelularContable"
                  placeholder="# de Celular"
                  value={formData.CelularContable || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="FijoContable"> </label>
                <input
                  type="text"
                  id="FijoContable"
                  name="FijoContable"
                  placeholder=" # de Telefono fijo"
                  value={formData.FijoContable || ""}
                  onChange={handleInputChange}
                  maxlength="10"
                />
              </div>
              <div>
                <label htmlFor="CorreoContable"></label>
                <input
                  type="email"
                  id="CorreoContable"
                  name="CorreoContable"
                  placeholder="Correo Area Contable"
                  value={formData.CorreoContable || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="DireccionContable "></label>
                <input
                  type="text"
                  id="DireccionContable"
                  name="DireccionContable"
                  placeholder="Dirrecion fisica"
                  value={formData.DireccionContable || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="CiudadContable"></label>
                <input
                  type="text"
                  id="CiudadContable"
                  name="CiudadContable"
                  placeholder="Ciudad"
                  value={formData.CiudadContable || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="CargoContable"></label>
                <input
                  type="text"
                  id="CargoContable"
                  name="CargoContable"
                  placeholder="Cargo"
                  value={formData.CargoContable || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="CedulaContable">Cédula</label>
                <p>
                  Adjunte un archivo en formato PDF de la cédula de la persona
                  encargada
                </p>
                <input
                  type="file"
                  id="CedulaContable"
                  name="CedulaContable"
                  accept="application/pdf"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label>
                  Acepto los <a href="#">términos y condiciones</a>
                </label>
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <Button
              className={`btn btn-success ${!termsAccepted ? "disabled" : ""}`}
              type="submit"
              disabled={!termsAccepted}
            >
              Enviar{" "}
            </Button>
          </fieldset>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Inicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Tu Aliado de confianza</h1>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="/">
                    Inicio
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Registro">
                    Registro usuarios
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Login">
                    Portal GetCar
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="formularioRegistro">
        <h2>FORMULARIO REGISTRO DE USUARIOS </h2>
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progress}%` }}
          ></div>
          <div
            className="cart-icon-container"
            style={{ left: getCartIconPosition() }}
          >
            <FaTruck className="cart-icon" />
          </div>
        </div>

        <div className="step-numbers">
          <span
            className={currentStep === 1 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(1)}
          >
            1
          </span>
          <span
            className={currentStep === 2 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(2)}
          >
            2
          </span>
          <span
            className={currentStep === 3 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(3)}
          >
            3
          </span>
          <span
            className={currentStep === 4 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(4)}
          >
            4
          </span>
          <span
            className={currentStep === 5 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(5)}
          >
            5
          </span>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-9">
              <div className="card">
                <div className="row no-gutters">
                  <div className="col">
                    <div className="card-body">
                      <form
                        onSubmit={handleSubmit}
                        classname="formulario-registro"
                      >
                        {currentStep === 1 && renderFormStep()}
                        {currentStep === 2 && renderFormStep()}
                        {currentStep === 3 && renderFormStep()}
                        {currentStep === 4 && renderFormStep()}
                        {currentStep === 5 && renderFormStep()}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FormularioCliente;
