import React, { useEffect, useState } from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  RiArrowDownSLine,
} from "react-icons/ri";
import {
  FaEnvelope,
  FaDollarSign,
  FaFile,
  FaUser,
  FaCalendar,
} from "react-icons/fa"; // Importar los iconos que necesitas

function BarraLateralAdmin() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  let navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <Navbar
      expand="md"
      variant="light"
      className="col-md-3 col-lg-2 d-md-block sidebar custom-navbar"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu} />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={`${isMenuOpen ? "show" : ""}`}
      >
        <Nav className="flex-column">
          <div className="menu-box">
            <Nav.Link href="/Admin">
              <FaUser /> Clientes aprobados y no aprobados
            </Nav.Link>
          </div>
            <div className="menu-box">
            <Nav.Link href="/SolicitusServiciosAdmin">
              <FaFile className="white-icon" />{" "}
Crear un servicio
            </Nav.Link>
          </div>
          <div className="menu-box">
            <NavDropdown
              title={
                <>
                  <FaCalendar className="white-icon" />{" "}
                  {/* Agregar el icono de calendario */}
                  <span>
                    Históricos
                    <RiArrowDownSLine
                      className={`arrow-icon ${isDropdownOpen ? "active" : ""}`}
                    />
                  </span>
                </>
              }
              id="historicos-dropdown"
            >
              <NavDropdown.Item href="/HistoricoViajes">
                Viajes solicitados
              </NavDropdown.Item>
              <NavDropdown.Item href="/HistoricoReportes">
                Reportes de entrega
              </NavDropdown.Item>
            </NavDropdown>
          </div>
          <div className="menu-box">
            <Nav.Link href="/Construccion">
              <FaDollarSign className="white-icon" />
              Estados de Cuenta y Soportes de Pago
            </Nav.Link>
          </div>
          <div className="menu-box">
            <Nav.Link href="/PQRAdmin">
              <FaEnvelope className="white-icon" />
              Reclamaciones, PQR y Solicitudes
            </Nav.Link>
          </div>
          <div className="logout">
            <Nav.Link onClick={logOut}>
              <RiLogoutCircleLine className="logout-icon" />
              Cerrar sesión
            </Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default BarraLateralAdmin;
