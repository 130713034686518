import React, { useState, useEffect } from "react";
import { API_URL, showMessage } from "../util/util";
import { useNavigate } from "react-router-dom";
import { parse } from "date-fns";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { PictureAsPdf as PdfIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../images/icons/GETCAR_COLOR.png";

const ConveyorForms = () => {
  const [clientId, setClientId] = useState(null);
  const [clientForms, setClientForms] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTermService, setSearchTermService] = useState(""); // Agregar esta variable
  const [originalClients, setOriginalClients] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isDescendingOrder, setIsDescendingOrder] = useState(true);
  const authData = localStorage.getItem("authData");
  let navigate = useNavigate();

  useEffect(() => {
    if (!authData) {
      navigate("/");
      showMessage(
        "Error!",
        " No tienes los permisos para acceder",
        "error",
        "Reintentar"
      );
    } else {
      const parsedAuthData = JSON.parse(authData);
      setClientId(parsedAuthData.id);
      fetchClientForms(parsedAuthData.id);
    }
  }, [authData]);

  const fetchClientForms = async (clientId) => {
    try {
      const response = await fetch(API_URL + `guide/clients/${clientId}/forms`);
      if (response.ok) {
        const forms = await response.json();
        forms.sort((a, b) => {
          const dateA = parse(a.date, "dd/MM/yyyy HH:mm:ss", new Date());
          const dateB = parse(b.date, "dd/MM/yyyy HH:mm:ss", new Date());
          return dateB - dateA;
        });
        setClientForms(forms);
        setOriginalClients(forms);
      } else {
        console.error("Error al obtener los formularios");
      }
    } catch (error) {
      console.error("Error al obtener los formularios:", error);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    setIsSearching(true);

    // Definir las variables parsedStartDate y parsedEndDate
    const parsedStartDate = startDate
      ? parse(startDate, "yyyy-MM-dd", new Date())
      : null;
    const parsedEndDate = endDate
      ? parse(endDate, "yyyy-MM-dd", new Date())
      : null;

    // Filtrar por fechas si se ingresan fechas
    const filteredFormsByDate =
      startDate && endDate
        ? clientForms.filter((form) => {
            const formDate = parse(
              form.date.substring(0, 10),
              "dd/MM/yyyy",
              new Date()
            );

            return formDate >= parsedStartDate && formDate <= parsedEndDate;
          })
        : clientForms;

    // Filtrar por nombre del servicio si se ingresa un término de búsqueda
    const filteredFormsByName = searchTermService
      ? filteredFormsByDate.filter((form) => {
          return form.nombreServicio
            .toLowerCase()
            .includes(searchTermService.toLowerCase());
        })
      : filteredFormsByDate;

    // Actualizar el estado clientForms con los resultados del filtro
    setClientForms(filteredFormsByName);
    if (searchTermService === "") {
      setIsSearching(false);
    }
  };

  const toggleSortOrder = () => {
    // Cambiar el orden al contrario del orden actual
    setIsDescendingOrder(!isDescendingOrder);

    // Ordenar la lista de clientes en consecuencia
    const sortedForms = [...clientForms].sort((a, b) => {
      const dateA = parse(a.date, "dd/MM/yyyy HH:mm:ss", new Date()).getTime();
      const dateB = parse(b.date, "dd/MM/yyyy HH:mm:ss", new Date()).getTime();
  
      return isDescendingOrder ? dateB - dateA : dateA - dateB;
    });

    // Actualizar la lista de clientes con el nuevo orden
    setClientForms(sortedForms);
  };

  const clearFilter = () => {
    setClientForms([...originalClients]);
    setIsSearching(false); // Restablecer isSearching a false
    setSearchTermService(""); // Restablecer el término de búsqueda
    setStartDate(""); // Restablecer la fecha de inicio
    setEndDate(""); // Restablecer la fecha de fin
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Configurar la orientación como horizontal
      fontSize: 6,
    });

    doc.autoTable({
      cellStyles: {
        // Permite el ajuste automático del texto dentro de las celdas
        cellWidth: "wrap",
      },
      head: [
        [
          "Nombre Transportador ",
          "Num Cedula",
          "Nombre Empresa",
          "Nombre Servicio",
          "Hora-Inicio",
          "Hora-Despacho",
          "Hora-Final",
          "Devoluciones",
          "Observacion Devoluciones",
          "Tipo de servicio ",
          "Observaciones ",
          "Nombre Receptor",
        ],
      ],
      body: clientForms.map((form) => [
        form.nombreTransportador,
        form.numCedula,
        form.nombreEmpresa,
        form.nombreServicio,
        form.horaInicio,
        form.horaDespacho,
        form.horaFinal,
        form.devoluciones,
        form.observacionDevoluciones,
        form.tipodeservicio,
        form.observaciones,
        form.nombreReceptor,
      ]),
    });
    // Cargar la imagen de la marca de agua
    const watermarkImg = new Image();
    watermarkImg.src = logo; // Asignar la variable logo como fuente

    // Esperar a que se cargue la imagen antes de continuar
    watermarkImg.onload = function () {
      // Agregar la imagen de la marca de agua en la esquina inferior derecha
      const logoWidth = 50; // Ancho deseado del logo
      const logoHeight = (logoWidth / watermarkImg.width) * watermarkImg.height;

      const xPosLogo = doc.internal.pageSize.getWidth() - logoWidth - 10; // Ajusta el margen derecho
      const yPosLogo = doc.internal.pageSize.getHeight() - logoHeight - 10; // Ajusta el margen inferior

      doc.addImage(
        watermarkImg,
        "PNG",
        xPosLogo,
        yPosLogo,
        logoWidth,
        logoHeight
      );
     
      // Ajustar el tamaño de fuente para el texto adicional
      doc.setFontSize(14); // Puedes ajustar el tamaño según tus necesidades

      // Agregar texto en la parte inferior izquierda
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();

      doc.text(
        "Historico de reportes de entrega",
        10,
        doc.internal.pageSize.getHeight() - 20
      );
      doc.text(
        `Generado el: ${formattedDate}`,
        10,
        doc.internal.pageSize.getHeight() - 10
      );

      // Restaurar el tamaño de fuente original
      doc.setFontSize(6);

      // Guardar el documento
      doc.save("Historico_reportes.pdf");
    };
  };
  
  const formStyle = {
    padding: "15px",
    marginBottom: "10px",
  };

  return (
    <div>
      <h1> Historico de reportes de entrega</h1>
      <div style={formStyle}>
        <form onSubmit={handleFilter}>
          <TextField
            type="text"
            label="Buscar por nombre del servicio"
            value={searchTermService}
            onChange={(e) => setSearchTermService(e.target.value)}
            disabled={isSearching}
          />
          <TextField
            type="date"
            label="Fecha de inicio"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            disabled={isSearching}
          />
          <TextField
            type="date"
            label="Fecha de fin"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            disabled={isSearching}
          />
          <Button type="submit" variant="contained" color="primary">
            Filtrar
          </Button>
          .
          <Button variant="contained" color="secondary" onClick={clearFilter}>
            Borrar filtro
          </Button>
        </form>
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PdfIcon />}
        onClick={downloadPDF}
      >
        Descargar PDF
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="Formularios del Cliente">
          <TableHead>
            <TableRow>
              <TableCell>
                {/* Agregar un botón para cambiar el orden de fecha */}
                <Button
                  variant="text"
                  color="primary"
                  onClick={toggleSortOrder}
                >
                  Fecha {isDescendingOrder ? "▼" : "▲"}
                </Button>
              </TableCell>{" "}
              <TableCell>Nombre Servicio</TableCell>
              <TableCell>Nombre Transportador</TableCell>
              <TableCell>Num Cedula</TableCell>
              <TableCell>Nombre Empresa</TableCell>
              <TableCell>Descripcion Servicios</TableCell>
              <TableCell>Despacho</TableCell>
              <TableCell>Hora Inicio</TableCell>
              <TableCell>Hora Despacho</TableCell>
              <TableCell>Hora Final</TableCell>
              <TableCell>Devoluciones</TableCell>
              <TableCell>Observacion Devoluciones </TableCell>
              <TableCell>Albaran</TableCell>
              <TableCell>Tipo de servicio</TableCell>
              <TableCell>Observaciones</TableCell>
              <TableCell>Nombre Receptor</TableCell>
              <TableCell>Facturas </TableCell>
              <TableCell>Foto receptor </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientForms.map((form) => (
              <TableRow key={form.id}>
                <TableCell>{form.date}</TableCell>
                <TableCell>{form.nombreServicio}</TableCell>
                <TableCell>{form.nombreTransportador}</TableCell>
                <TableCell>{form.numCedula}</TableCell>
                <TableCell>{form.nombreEmpresa}</TableCell>
                <TableCell>{form.descripcionServicios}</TableCell>
                <TableCell>{form.despacho}</TableCell>
                <TableCell>{form.horaInicio}</TableCell>
                <TableCell>{form.horaDespacho}</TableCell>
                <TableCell>{form.horaFinal}</TableCell>
                <TableCell>{form.devoluciones}</TableCell>
                <TableCell>{form.observacionDevoluciones}</TableCell>
                <TableCell>{form.albaran}</TableCell>
                <TableCell>{form.tipodeservicio}</TableCell>
                <TableCell>{form.observaciones}</TableCell>
                <TableCell>{form.nombreReceptor}</TableCell>
                <TableCell>
                  {form.facturaFile && (
                    <a
                      href={`data:application/pdf;base64,${form.facturaFile}`}
                      download={`factura_${form.id}.pdf`}
                    >
                      Descargar Factura
                    </a>
                  )}
                </TableCell>
                <TableCell>
                  {form.fotoReceptorFile && (
                    <a
                      href={`data:application/pdf;base64,${form.fotoReceptorFile}`}
                      download={`foto_receptor_${form.id}.pdf`}
                    >
                      Descargar Foto Receptor
                    </a>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ConveyorForms;
