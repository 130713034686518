import React from "react";
import { useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { API_URL } from "../util/util";
import { Button } from "react-bootstrap";
import { showMessage } from "../util/util";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import logo from "../images/icons/GETCAR_COLOR.png";

import "./Contrato.css";

const Contrato = () => {
  let navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [progress, setProgress] = useState(0);
  const [mismoPropietario, setMismoPropietario] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(
    localStorage.getItem("formSubmitted") === "true"
  );

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "file") {
      const file = event.target.files[0];
      setFormData((prevFormData) => ({ ...prevFormData, [name]: file }));
    } else if (name === "mismoPropietario" && type === "checkbox") {
      const isChecked = event.target.checked;

      if (isChecked) {
        // Copiar los campos del propietario al conductor
        setFormData((prevFormData) => ({
          ...prevFormData,
          NombresYapellidosConductor: prevFormData.NombresYapellidos,
          NumCedulaConductor: prevFormData.NumCedula,
          FechaCedulaConductor: prevFormData.FechaCedula,
          LugarCedulaConductor: prevFormData.LugarCedula,
          CelularConductor: prevFormData.Celular,
          FijoConductor: prevFormData.Fijo,
          CorreoConductor: prevFormData.Correo,
          DireccionConductor: prevFormData.Direccion,
          CiudadConductor: prevFormData.Ciudad,
        }));
      } else {
        // Restablecer los campos del conductor si no es el mismo propietario
        setFormData((prevFormData) => ({
          ...prevFormData,
          NombresYapellidosConductor: "",
          NumCedulaConductor: "",
          FechaCedulaConductor: "",
          LugarCedulaConductor: "",
          CelularConductor: "",
          FijoConductor: "",
          CorreoConductor: "",
          DireccionConductor: "",
          CiudadConductor: "",
        }));
      }
    } else {
      const updatedValue = value.toUpperCase();
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: updatedValue,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

        // Realizar validaciones antes de enviar el formulario
    const requiredFields = [
      "Placa",
      "Tipo",
      "Chasis",
      "Clase",
      "Motor",
      "Color",
      "Modelo",
      "Soat",
      "Tecnomecanica",
      "Tarjetadepropiedad",
      "Soatfile",
      "tecnomecanica",
      "NombreEmpresaMonitoreo",
      "UsuarioEmpresaMonitoreo",
      "ContraseñaEmpresaMonitoreo",
      "NombresYapellidos",
      "NumCedula",
      "FechaCedula",
      "LugarCedula",
      "Celular",
      "Fijo",
      "Correo",
      "Direccion",
      "Ciudad",
      "CedulaPropietario",
      "NombresYapellidosConductor",
      "NumCedulaConductor",
      "FechaCedulaConductor",
      "LugarCedulaConductor",
      "CelularConductor",
      "FijoConductor",
      "CorreoConductor",
      "DireccionConductor",
      "EpsConductor",
      "ArlConductor",
      "NumLicenciaConductor",
      "CategoriaLicenciaConductor",
      "FechaVenLicenciaConductor",
      "CedulaConductor",
      "Licencia",
      "NombresyApellidosEmergencia",
      "TelefonoEmergencia",
      "ParentescoEmergencia",
      "EmpresaReferencia",
      "TelefonoLaboral",
      "ObservacionesLaboral",
      "EmpresaReferenciaPersonalesConductor",
      "TelefonoPersonalesConductor",
      "ParentescoPersonalesConductor",
      "EmpresaReferenciaPersonalesPropietario",
      "TelefonoPersonalesPropietario",
      "ParentescoPersonalesPropietario",
    ];

    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      const errorMessage = `Los siguientes campos son obligatorios: ${missingFields.join(
        ", "
      )}`;
      showMessage("Error", errorMessage, "error", "Aceptar");
      return;
    }

    try {
      const formDataToSend = new FormData();

      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      try {
        Swal.fire({
          title: "Cargando",
          html: '<div class="loader"></div><p>Enviando formulario...</p>',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(API_URL + "contract/contract", {
          method: "POST",
          body: formDataToSend,
        });

        Swal.close();

        if (response.ok) {
          setFormSubmitted(true);
          localStorage.setItem("formSubmitted", "true");
          showMessage(
            "success!",
            "Contrato enviado. Espere que nos comuniquemos con usted.",
            "success",
            "Continuar"
          );
        } else {
          showMessage(
            "Error",
            "Error al enviar el formulario",
            "error",
            "Aceptar"
          );
        }
      } catch (error) {
        console.error("Error al enviar el formulario", error);
        showMessage(
          "Error",
          "Error al enviar el formulario: " + error.message,
          "error",
          "Aceptar"
        );
      }
    } catch (error) {
      console.error("Error al procesar el formulario", error);
    }
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
    setProgress(step * 18);
  };

  const handleNext = () => {
    if (currentStep < 6) {
      setCurrentStep(currentStep + 1);
    }
    setProgress((currentStep + 1) * 18);
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
    setProgress((currentStep - 1) * 18);
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  if (formSubmitted) {
    showMessage(
      "Formulario completado",
      "Usted ya ha diligenciado este formulario.",
      "info",
      "Aceptar"
    );
    return null;
  }

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <fieldset>
            <legend>Datos del Vehiculo</legend>
            <div className="Datosdelvehiculo">
              <div>
                <label htmlFor="Placa"></label>
                <input
                  type="text"
                  id="Placa"
                  name="Placa"
                  placeholder="# Placa del vehiculo"
                  value={formData.Placa || ""}
                  onChange={handleInputChange}
                  maxlength="7"
                />
              </div>
              <h4>Selecione el tipo de servicio</h4>
              <div>
                <select
                  name="Tipo"
                  value={formData.Tipo || ""}
                  onChange={handleInputChange}
                >
                  <option></option>
                  <option>Camion llanta sencilla</option>
                  <option>Mini van</option>
                  <option>Motorizado</option>
                </select>
              </div>
              <div>
                <label htmlFor="Chasis"></label>
                <input
                  type="text"
                  id="Chasis"
                  name="Chasis"
                  placeholder="Ingresa el # de chasis"
                  value={formData.Chasis || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="Clase"></label>
                <input
                  type="text"
                  id="Clase"
                  name="Clase"
                  placeholder="Ingresa la clase de tu vehiculo"
                  value={formData.Clase || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="Motor"></label>
                <input
                  type="text"
                  id="Motor"
                  name="Motor"
                  placeholder="Ingresa el Motor de tu vehiculo"
                  value={formData.Motor || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="Color"></label>
                <input
                  type="text"
                  id="Color"
                  name="Color"
                  placeholder="Ingresa el color de tu vehiculo"
                  value={formData.Color || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="Modelo"></label>
                <input
                  type="text"
                  id="Modelo"
                  name="Modelo"
                  placeholder="Ingresa el Modelo de tu vehiculo"
                  value={formData.Modelo || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="Soat"> </label>
                <p>Fecha de vencimiento de Soat</p>
                <input
                  type="date"
                  id="Soat"
                  name="Soat"
                  value={formData.Soat || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="Tecnomecanica"> </label>
                <p>Fecha de vencimiento de Tecnomecanica</p>
                <input
                  type="date"
                  id="Tecnomecanica"
                  name="Tecnomecanica"
                  value={formData.Tecnomecanica || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="Tarjetadepropiedad">Tarjeta de propiedad</label>
                <p>
                  Adjunte un archivo en formato PDF de la Tarjeta de propiedad
                </p>
                <input
                  type="file"
                  id="Tarjetadepropiedad"
                  name="Tarjetadepropiedad"
                  accept=".pdf"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="Soatfile">Soat</label>
                <p>Adjunte un archivo en formato PDF del Soat</p>
                <input
                  type="file"
                  id="Soatfile"
                  name="Soatfile"
                  accept=".pdf"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="tecnomecanica">tecnomecanica</label>
                <p>Adjunte un archivo en formato PDF de la tecnomecanica</p>
                <input
                  type="file"
                  id="tecnomecanica"
                  name="tecnomecanica"
                  accept=".pdf"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 2:
        const handleNoAplicaChange = (event) => {
          const { checked } = event.target;

          if (checked) {
            const noAplicaData = {
              NombreEmpresaMonitoreo: "No aplica",
              UsuarioEmpresaMonitoreo: "No aplica",
              ContraseñaEmpresaMonitoreo: "No aplica",
            };

            setFormData({ ...formData, ...noAplicaData });
          } else {
            // Restablecer los valores a vacío o null si es necesario
            // Ejemplo:
            const resetData = {
              NombreEmpresaMonitoreo: "",
              UsuarioEmpresaMonitoreo: "",
              ContraseñaEmpresaMonitoreo: "",
            };

            setFormData({ ...formData, ...resetData });
          }
        };
        return (
          <fieldset>
            <legend>Empresa Monitoreo Gps</legend>
            <div className="EmpresaMonitoreoGps">
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="noAplicaAuxiliar"
                    checked={
                      formData.NombreEmpresaMonitoreo === "No aplica" &&
                      formData.UsuarioEmpresaMonitoreo === "No aplica" &&
                      formData.ContraseñaEmpresaMonitoreo === "No aplica"
                    }
                    onChange={handleNoAplicaChange}
                  />
                  No cuenta con Empresa de Monitoreo
                </label>
              </div>
              <div>
                <label htmlFor="NombreEmpresaMonitoreo"></label>
                <input
                  type="text"
                  id="NombreEmpresaMonitoreo"
                  name="NombreEmpresaMonitoreo"
                  placeholder="Nombre Empresa Monitoreo"
                  value={formData.NombreEmpresaMonitoreo || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="UsuarioEmpresaMonitoreo"></label>
                <input
                  type="text"
                  id="UsuarioEmpresaMonitoreo"
                  name="UsuarioEmpresaMonitoreo"
                  placeholder="Usuario Empresa Monitoreo"
                  value={formData.UsuarioEmpresaMonitoreo || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="ContraseñaEmpresaMonitoreo"></label>
                <input
                  type="text"
                  id="ContraseñaEmpresaMonitoreo"
                  name="ContraseñaEmpresaMonitoreo"
                  placeholder="Contraseña Empresa Monitoreo"
                  value={formData.ContraseñaEmpresaMonitoreo || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 3:
        return (
          <fieldset>
            <legend>Datos del Propietario</legend>

            <div className="DatosdelPropietario">
              <div>
                <label htmlFor="NombresYapellidos"></label>
                <input
                  type="text"
                  id="NombresYapellidos"
                  name="NombresYapellidos"
                  placeholder="Nombres y apellidos"
                  value={formData.NombresYapellidos || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="NumCedula"></label>
                <input
                  type="text"
                  id="NumCedula"
                  name="NumCedula"
                  placeholder="Numero de cedula"
                  value={formData.NumCedula || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="FechaCedula"></label>
                <p>Fecha de expedicion de la cedula</p>
                <input
                  type="date"
                  id="FechaCedula"
                  name="FechaCedula"
                  value={formData.FechaCedula || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="LugarCedula"></label>
                <input
                  type="text"
                  id="LugarCedula"
                  name="LugarCedula"
                  placeholder="Lugar expedicion de la cedula "
                  value={formData.LugarCedula || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Celular"></label>
                <input
                  type="text"
                  id="Celular"
                  name="Celular"
                  placeholder="# de Celular"
                  value={formData.Celular || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="Fijo"> </label>
                <input
                  type="text"
                  id="Fijo"
                  name="Fijo"
                  placeholder=" # de fijo del propietario"
                  value={formData.Fijo || ""}
                  onChange={handleInputChange}
                  maxlength="10"
                />
              </div>
              <div>
                <label htmlFor="Correo"></label>
                <input
                  type="email"
                  id="Correo"
                  name="Correo"
                  placeholder="Correo"
                  value={formData.Correo || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="Direccion "></label>
                <input
                  type="text"
                  id="Direccion"
                  name="Direccion"
                  placeholder="Direccionn fisica"
                  value={formData.Direccion || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="Ciudad"></label>
                <input
                  type="text"
                  id="Ciudad"
                  name="Ciudad"
                  placeholder="Ciudad"
                  value={formData.Ciudad || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="CedulaPropietario">Cédula</label>
                <p>
                  Adjunte un archivo en formato PDF de la cédula del propietario
                </p>
                <input
                  type="file"
                  id="CedulaPropietario"
                  name="CedulaPropietario"
                  accept=".pdf"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 4:
        return (
          <fieldset>
            <legend>Datos del Conductor</legend>

            <div className="DatosdelConductor">
              <div>
                <label htmlFor="mismoPropietario">
                  ¿El conductor es el mismo propietario?
                </label>
                <input
                  type="checkbox"
                  id="mismoPropietario"
                  name="mismoPropietario"
                  checked={mismoPropietario}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="NombresYapellidosConductor"></label>
                <input
                  type="text"
                  id="NombresYapellidosConductor"
                  name="NombresYapellidosConductor"
                  placeholder="Nombres y apellidos del conductor"
                  value={formData.NombresYapellidosConductor || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="NumCedulaConductor"></label>
                <input
                  type="text"
                  id="NumCedulaConductor"
                  name="NumCedulaConductor"
                  placeholder="Numero de cedula del conductor"
                  value={formData.NumCedulaConductor || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="FechaCedulaConductor"></label>
                <p>Fecha de expedicion de la cedula del conductor</p>
                <input
                  type="date"
                  id="FechaCedulaConductor"
                  name="FechaCedulaConductor"
                  value={formData.FechaCedulaConductor || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="LugarCedulaConductor"></label>
                <input
                  type="text"
                  id="LugarCedulaConductor"
                  name="LugarCedulaConductor"
                  placeholder="Lugar expedicion de la cedula del conductor"
                  value={formData.LugarCedulaConductor || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="CelularConductor"></label>
                <input
                  type="text"
                  id="CelulaConductorr"
                  name="CelularConductor"
                  placeholder="# de Celular del conductor"
                  value={formData.CelularConductor || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="FijoConductor"> </label>
                <input
                  type="text"
                  id="FijoConductor"
                  name="FijoConductor"
                  placeholder=" # de telefono fijo del conductor"
                  value={formData.FijoConductor || ""}
                  onChange={handleInputChange}
                  maxlength="10"
                />
              </div>
              <div>
                <label htmlFor="CorreoConductor"></label>
                <input
                  type="email"
                  id="CorreoConductor"
                  name="CorreoConductor"
                  placeholder="Correo del conductor"
                  value={formData.CorreoConductor || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="DireccionConductor "></label>
                <input
                  type="text"
                  id="DireccionConductor"
                  name="DireccionConductor"
                  placeholder="Direccionn fisica conductor"
                  value={formData.DireccionConductor || ""}
                  onChange={handleInputChange}
                  maxlength="35"
                />
              </div>
              <div>
                <label htmlFor="CiudadConductor"></label>
                <input
                  type="text"
                  id="CiudadConductor"
                  name="CiudadConductor"
                  placeholder="Ciudad conductor"
                  value={formData.CiudadConductor || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="EpsConductor"></label>
                <input
                  type="text"
                  id="EpsConductor"
                  name="EpsConductor"
                  placeholder="Eps conductor"
                  value={formData.EpsConductor || ""}
                  onChange={handleInputChange}
                  maxlength="10"
                />
              </div>
              <div>
                <label htmlFor="ArlConductor"></label>
                <input
                  type="text"
                  id="Arl"
                  name="ArlConductor"
                  placeholder="Arl Conductor"
                  value={formData.ArlConductor || ""}
                  onChange={handleInputChange}
                  maxlength="10"
                />
              </div>
              <div>
                <label htmlFor="NumLicenciaConductor"></label>
                <input
                  type="text"
                  id="NumLicenciaConductor"
                  name="NumLicenciaConductor"
                  placeholder="Numero de licencia"
                  value={formData.NumLicenciaConductor || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="CategoriaLicenciaConductor">
                  Ingrese las categorías de su licencia de la siguiente manera.
                  Ejemplo: A2, B1, C1
                </label>
                <input
                  type="text"
                  id="CategoriaLicenciaConductor"
                  name="CategoriaLicenciaConductor"
                  placeholder="Categoría de la licencia"
                  value={formData.CategoriaLicenciaConductor || ""}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </div>
              <div>
                <label htmlFor="FechaVenLicenciaConductor"></label>
                <p>Fecha de vencimiento de la licencia</p>
                <input
                  type="date"
                  id="FechaVenLicenciaConductor"
                  name="FechaVenLicenciaConductor"
                  value={formData.FechaVenLicenciaConductor || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="CedulaConductor">Cédula</label>
                <p>Adjunte un archivo en formato PDF de la cédula</p>
                <input
                  type="file"
                  id="CedulaConductor"
                  name="CedulaConductor"
                  accept=".pdf"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="Licencia De conduccion">
                  Licencia De conduccion
                </label>
                <p>Adjunte un archivo en formato PDF de la licencia</p>
                <input
                  type="file"
                  id="Licencia"
                  name="Licencia"
                  accept=".pdf"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 5:
        return (
          <fieldset>
            <legend>Contacto en caso de emergencia</legend>
            <div className="ConctactoEmergencia">
              <div>
                <label htmlFor="NombresyApellidosEmergencia"></label>
                <input
                  type="text"
                  id="NombresyApellidosEmergencia"
                  name="NombresyApellidosEmergencia"
                  placeholder="Nombres y Apellidos"
                  value={formData.NombresyApellidosEmergencia || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="TelefonoEmergencia"> </label>
                <input
                  type="text"
                  id="TelefonoEmergencia"
                  name="TelefonoEmergencia"
                  placeholder="Telefono"
                  value={formData.TelefonoEmergencia || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="ParentescoEmergencia"></label>
                <input
                  type="text"
                  id="ParentescoEmergencia"
                  name="ParentescoEmergencia"
                  placeholder="Parentesco de la persona"
                  value={formData.ParentescoEmergencia || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
            </div>
            <legend>
              Verificacion de referencias laborales o comerciales del
              propietario{" "}
            </legend>
            <div className="ReferenciasLaborales">
              <div>
                <label htmlFor="EmpresaReferencia"></label>
                <input
                  type="text"
                  id="EmpresaReferencia"
                  name="EmpresaReferencia"
                  placeholder=" Nombre de la empresa , nombre contacto comercial "
                  value={formData.EmpresaReferencia || ""}
                  onChange={handleInputChange}
                  maxlength="20"
                />
              </div>
              <div>
                <label htmlFor="TelefonoLaboral"> </label>
                <input
                  type="text"
                  id="TelefonoLaboral"
                  name="TelefonoLaboral"
                  placeholder="Numero de Contacto"
                  value={formData.TelefonoLaboral || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="ObservacionesLaboral"></label>
                <input
                  type="text"
                  id="ObservacionesLaboral"
                  name="ObservacionesLaboral"
                  placeholder="Observaciones"
                  value={formData.ObservacionesLaboral || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <button type="button" class="btn btn-primary" onClick={handleNext}>
              Siguiente
            </button>
          </fieldset>
        );
      case 6:
        return (
          <fieldset>
            <legend>Verificacion de referencias personales (Conductor)</legend>

            <div className="ReferenciasPersonalesConductor">
              <div>
                <label htmlFor="EmpresaReferenciaPersonalesConductor"></label>
                <input
                  type="text"
                  id="EmpresaReferenciaPersonalesConductor"
                  name="EmpresaReferenciaPersonalesConductor"
                  placeholder="Nombres y Apellidos"
                  value={formData.EmpresaReferenciaPersonalesConductor || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="TelefonoPersonalesConductor"> </label>
                <input
                  type="text"
                  id="TelefonoPersonalesConductor"
                  name="TelefonoPersonalesConductor"
                  placeholder="Numero de Celular"
                  value={formData.TelefonoPersonalesConductor || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="ParentescoPersonalesConductor"></label>
                <input
                  type="text"
                  id="ParentescoPersonalesConductor"
                  name="ParentescoPersonalesConductor"
                  placeholder="Parentesco"
                  value={formData.ParentescoPersonalesConductor || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
            </div>
            <legend>
              Verificacion de referencias personales (Propietario)
            </legend>
            <div className="ReferenciasPersonalesPropietario">
              <div>
                <label htmlFor="EmpresaReferenciaPersonalesPropietario"></label>
                <input
                  type="text"
                  id="EmpresaReferenciaPersonalesPropietario"
                  name="EmpresaReferenciaPersonalesPropietario"
                  placeholder="Nombres y Apellidos"
                  value={formData.EmpresaReferenciaPersonalesPropietario || ""}
                  onChange={handleInputChange}
                  maxlength="30"
                />
              </div>
              <div>
                <label htmlFor="TelefonoPersonalesPropietario"> </label>
                <input
                  type="text"
                  id="TelefonoPersonalesPropietario"
                  name="TelefonoPersonalesPropietario"
                  placeholder="Numero de Celular"
                  value={formData.TelefonoPersonalesPropietario || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <label htmlFor="ParentescoPersonalesPropietario"></label>
                <input
                  type="text"
                  id="ParentescoPersonalesPropietario"
                  name="ParentescoPersonalesPropietario"
                  placeholder="Parentesco"
                  value={formData.ParentescoPersonalesPropietario || ""}
                  onChange={handleInputChange}
                  maxlength="15"
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label>
                  Acepto los <a href="#">términos y condiciones</a>
                </label>
              </div>
            </div>
            <button type="button" class="btn btn-danger" onClick={handleBack}>
              Atrás
            </button>
            <Button
              className={`btn btn-success ${!termsAccepted ? "disabled" : ""}`}
              type="submit"
              disabled={!termsAccepted}
            >
              Enviar{" "}
            </Button>
          </fieldset>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Inicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Tu Aliado de confianza</h1>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
      </header>
      <main className="Contrato">
        <h2>FORMULARIO PARA CARGA DE TRANSPORTE TERRESTRE</h2>
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <div className="step-numbers">
          <span
            className={currentStep === 1 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(1)}
          >
            1
          </span>
          <span
            className={currentStep === 2 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(2)}
          >
            2
          </span>
          <span
            className={currentStep === 3 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(3)}
          >
            3
          </span>
          <span
            className={currentStep === 4 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(4)}
          >
            4
          </span>
          <span
            className={currentStep === 5 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(5)}
          >
            5
          </span>
          <span
            className={currentStep === 6 ? "step-number active" : "step-number"}
            onClick={() => handleStepClick(6)}
          >
            6
          </span>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-9">
              <div className="card">
                <div className="row no-gutters">
                  <div className="col">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {currentStep === 1 && renderFormStep()}
                        {currentStep === 2 && renderFormStep()}
                        {currentStep === 3 && renderFormStep()}
                        {currentStep === 4 && renderFormStep()}
                        {currentStep === 5 && renderFormStep()}
                        {currentStep === 6 && renderFormStep()}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contrato;
