import React from "react";
import Inicio from "./inicio/Inicio";
import { Register } from "./register/Register";
import { Login } from "./login/Login";
import Formularios from "./formularios/Formularios";
import FormularioCliente from "./formulariocliente/FormularioCliente";
import ContratoComodato from "./contrato/Contrato";
import SolicitudServicios from "./SolicitudServicios/SolicitudServicios";
import SolicitusServiciosAdmin from "./SolicitudServiciosAdmin/SolicitudServiciosAdmin";
import ServiciosTransporte from "./ServiciosTransporte/ServiciosTransporte";
import Admin from "./admin/Admin";
import Perfil from "./Perfil/Perfil";
import ArchivosCliente from "./ArchivosCliente/ArchivosCliente";
import HistoricoViajes from "./HistoricoViajes/HistoricoViajes";
import HistoricoViajesCliente from "./HistoricoViajesClient/HistoricoViajesClient";
import HistoricoReportes from "./HistoricoReportes/HistoricoReportes";
import HistoricoReportesCliente from "./HistoricoReportesClient/HistoricoReportesClient";
import HistoricoReportesConductor from "./HistoricoReportesConductor/HistoricoReportesConductor";
import Construccion from "./Construccion/Construccion";
import PQR from "./PQR/PQR";
import PQRAdmin from "./PQRAdmin/PQRAdmin";
import WhatsAppLogo from "./WhatsAppLogo/WhatsAppLogo";
import BarraLateral from "./NavBarLateral/NavBarLateral";
import BarraLateralAdmin from "./NavBarLateralAdmin/NavBarLateralAdmin";

import Cotizador from "./Cotizador/Cotizador";
import Rastreo from "./Rastreo/Rastreo";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
></link>;

function App() {
  const showWhatsAppLogo = [
    "/",
    "Admin",
    "Registro",
    "FormularioCliente",
    "Admin",
  ].includes(window.location.pathname);

  return (
    <div className="App">
      {showWhatsAppLogo && <WhatsAppLogo />}
      <Router>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/Registro" element={<Register />} />
          <Route path="/FormularioCliente" element={<FormularioCliente />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Contrato" element={<ContratoComodato />} />
          <Route path="/Cotizador" element={<Cotizador />} />

          <Route
            path="/HistoricoReportes"
            element={
              <div className="wrapper">
                <BarraLateralAdmin />
                <div className="content">
                  <HistoricoReportes />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/HistoricoViajes"
            element={
              <div className="wrapper">
                <BarraLateralAdmin />
                <div className="content">
                  <HistoricoViajes />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/Admin"
            element={
              <div className="wrapper">
                <BarraLateralAdmin />
                <div className="content">
                  <Admin />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/SolicitusServiciosAdmin"
            element={
              <div className="wrapper">
                <BarraLateralAdmin />
                <div className="content">
                  <SolicitusServiciosAdmin />
                </div>
              </div>
            }
          />{" "}
           <Route
            path="/PQRAdmin"
            element={
              <div className="wrapper">
                <BarraLateralAdmin />
                <div className="content">
                  <PQRAdmin />
                </div>
              </div>
            }
          />{" "}
           <Route
            path="/Rastreo"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <Rastreo />
                </div>
              </div>
            }
          />{" "}
              <Route
            path="/PQR"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <PQR />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/Perfil"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <Perfil />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/HistoricoReportesCliente"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <HistoricoReportesCliente />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/HistoricoReportesConductor"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <HistoricoReportesConductor />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/HistoricoViajesCliente"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <HistoricoViajesCliente />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/Formularios"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <Formularios />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/SolicitudServicios"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <SolicitudServicios />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/ArchivosCliente"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <ArchivosCliente />
                </div>
              </div>
            }
          />{" "}
          <Route
            path="/ServiciosTransporte"
            element={
              <div className="wrapper">
                <BarraLateral />
                <div className="content">
                  <ServiciosTransporte />
                </div>
              </div>
            }
          />
          <Route path="/Construccion" element={<Construccion />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
