import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Si estás utilizando React Router para manejar las rutas
import { API_URL, showMessage } from "../util/util";
import { useNavigate } from "react-router-dom";
import "./Perfil.css";

function ProfilePage() {
  let navigate = useNavigate();
  const { clientId } = useParams(); // Obtiene el ID del cliente de los parámetros de la URL
  const [clientInfo, setClientInfo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedClientInfo, setEditedClientInfo] = useState({});

  useEffect(() => {
    const authData = localStorage.getItem("authData");
    if (!authData) {
      // Si no hay datos de autenticación, redirige al usuario a la página de inicio
      // y muestra un mensaje de error
      navigate("/");
      showMessage(
        "Error",
        "Para acceder a los formularios, inicia sesión",
        "error",
        "Reintentar"
      );
    } else {
      // Si hay datos de autenticación, obtén la información completa del cliente
      const clientId = JSON.parse(authData).id; // Obtener el ID del cliente desde los datos de autenticación
      fetch(API_URL + `client/${clientId}/full-info`)
        .then((response) => response.json())
        .then((data) => {
          setClientInfo(data);
        })
        .catch((error) => {
          console.error("Error al obtener la información del cliente:", error);
        });
    }
  }, []);

  const handleFileUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file);
        uploadProfilePhoto(file);
      }
    };
    fileInput.click();
  };

  const uploadProfilePhoto = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URL + `client/${clientInfo.id}/upload-profile-photo`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setClientInfo(data);
        window.location.reload(); // Recargar la página
      })
      .catch((error) => {
        console.error("Error al subir la imagen de perfil:", error);
      });
  };

  const renderProfilePhoto = () => {
    if (clientInfo && clientInfo.profilePhoto) {
      return (
        <div className="profile-photo">
          <img
            src={`data:image/jpeg;base64,${clientInfo.profilePhoto}`}
            alt="Foto de perfil"
          />
          <div className="upload-overlay">
            <div className="upload-icon" onClick={handleFileUpload}>
              Cambiar foto
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="profile-photo">
          <div className="user-icon">
            <span role="img" aria-label="User Icon">
              🧑
            </span>
          </div>
          <div className="upload-overlay">
            <div className="upload-icon" onClick={handleFileUpload}>
              Subir imagen
            </div>
          </div>
        </div>
      );
    }
  };

  const handleEditClick = () => {
    // Activar el modo de edición al hacer clic en el botón de "Editar"
    setIsEditing(true);
    // Copiar los datos actuales del cliente en el estado de edición
    setEditedClientInfo({ ...clientInfo });
  };

  const handleCancelEdit = () => {
    // Cancelar la edición y volver a mostrar los datos originales
    setIsEditing(false);
    setEditedClientInfo({});
  };

  const handleSaveEdit = () => {
    // Enviar los datos editados al servidor para guardarlos
    fetch(API_URL + `client/${clientInfo.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedClientInfo),
    })
      .then((response) => response.json())
      .then((data) => {
        setClientInfo(data);
        setIsEditing(false); // Desactivar el modo de edición
      })
      .catch((error) => {
        console.error("Error al guardar la información actualizada:", error);
      });
  };

  const renderEditButton = () => {
    if (!isEditing) {
      return <button onClick={handleEditClick}>Editar</button>;
    } else {
      return (
        <>
          <button onClick={handleSaveEdit}>Guardar</button>
          <button onClick={handleCancelEdit}>Cancelar</button>
        </>
      );
    }
  };

  const renderClientInfo = () => {
    if (!isEditing) {
      // Mostrar información no editable cuando no esté en modo de edición
      return (
        <div className="profile-details">
          <h2>Información del Perfil</h2>
          <p>
            <strong>Nombre:</strong> {clientInfo.name} {clientInfo.lastName}
          </p>
          <p>
            <strong>Correo Electrónico:</strong> {clientInfo.email}
          </p>
          <p>
            <strong>Fecha de Nacimiento:</strong> {clientInfo.birthDate}
          </p>
          <p>
            <strong>Teléfono:</strong> {clientInfo.phone}
          </p>
          <p>
            <strong>Dirrecion:</strong> {clientInfo.address}
          </p>
          <p>
            <strong>Identificación:</strong> {clientInfo.identification}
          </p>
          <p>
            <strong>Empresa:</strong> {clientInfo.company}
          </p>
          <p>
            <strong>Aprobado:</strong> {clientInfo.approved ? "Sí" : "No"}
          </p>
          <p>
            <strong>Tipo de Usuario:</strong> {clientInfo.userType}
          </p>

          {renderEditButton()}
        </div>
      );
    } else {
      // Mostrar campos de edición cuando esté en modo de edición
      return (
        <div className="profile-details">
          <h2>Editar Información</h2>
          <label>Nombre</label>
          <input
            type="text"
            value={editedClientInfo.name || ""}
            onChange={(e) =>
              setEditedClientInfo({ ...editedClientInfo, name: e.target.value })
            }
          />
          <label>Correo</label>
          <input
            type="text"
            value={editedClientInfo.email || ""}
            onChange={(e) =>
              setEditedClientInfo({
                ...editedClientInfo,
                email: e.target.value,
              })
            }
          />
          <label>Fecha de nacimiento</label>

          <input
            type="text"
            value={editedClientInfo.birthDate || ""}
            onChange={(e) =>
              setEditedClientInfo({
                ...editedClientInfo,
                birthDate: e.target.value,
              })
            }
          />
          <label>Telefono</label>

          <input
            type="text"
            value={editedClientInfo.phone || ""}
            onChange={(e) =>
              setEditedClientInfo({
                ...editedClientInfo,
                phone: e.target.value,
              })
            }
          />
          <label>Direccion</label>

          <input
            type="text"
            value={editedClientInfo.address || ""}
            onChange={(e) =>
              setEditedClientInfo({
                ...editedClientInfo,
                address: e.target.value,
              })
            }
          />
          <label>Cedula</label>

          <input
            type="text"
            value={editedClientInfo.identification || ""}
            onChange={(e) =>
              setEditedClientInfo({
                ...editedClientInfo,
                identification: e.target.value,
              })
            }
          />
          <label>Empresa</label>

          <input
            type="text"
            value={editedClientInfo.company || ""}
            onChange={(e) =>
              setEditedClientInfo({
                ...editedClientInfo,
                company: e.target.value,
              })
            }
          />
          {renderEditButton()}
        </div>
      );
    }
  };

  return (
    <div className="profile-page">
      {renderProfilePhoto()}
      {clientInfo ? (
        renderClientInfo()
      ) : (
        <p>Cargando la información del cliente...</p>
      )}
    </div>
  );
}

export default ProfilePage;
