import React, { useEffect, useState } from "react";
import { API_URL, showMessage } from "../util/util";
import { parse } from "date-fns";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PictureAsPdf as PdfIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable"; // Importa el módulo autotable
import logo from "../images/icons/GETCAR_COLOR.png";

const ConveyorTable = () => {
  const [clients, setClients] = useState([]);
  const [editingClient, setEditingClient] = useState(null);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTermService, setSearchTermService] = useState(""); // Agregar esta variable
  const [searchTermClient, setSearchTermClient] = useState("");
  const [originalClients, setOriginalClients] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isDescendingOrder, setIsDescendingOrder] = useState(true);
  const authDataAdmin = localStorage.getItem("authDataAdmin");
  let navigate = useNavigate();

  useEffect(() => {
    if (!authDataAdmin) {
      navigate("/");
      showMessage(
        "Error!",
        " No tienes los permisos para acceder",
        "error",
        "Reintentar"
      );
    }
  }, []);

  useEffect(() => {
    // Fetch the data from the API endpoint
    fetch(API_URL + "guide/clients")
      .then((response) => response.json())
      .then((data) => {
        // Ordena los clientes por fecha de forma descendente (los más recientes primero)
        data.sort((a, b) => {
          const dateA = parse(a.date, "dd/MM/yyyy HH:mm:ss", new Date());
          const dateB = parse(b.date, "dd/MM/yyyy HH:mm:ss", new Date());
          return dateB - dateA;
        });
        setClients(data);
        setOriginalClients(data);
      })
      .catch((error) => {
        console.error("Error fetching clients data:", error);
      });
  }, []);

  const handleDeleteLegalizacion = (guideId) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar este servicio?"
    );

    if (confirmDelete) {
      fetch(API_URL + `guide/guide/${guideId}/delete`, {
        method: "DELETE",
      }).then((response) => {
        if (response.status === 200) {
          // La eliminación se realizó con éxito, muestra un mensaje de éxito
          alert("Legalizacion eliminada con éxito");
          // Actualiza la lista de servicios después de la eliminación
          window.location.reload();
        } else {
          // Manejar el error
          console.error("Error al eliminar el servicio.");
        }
      });
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    setIsSearching(true);

    // Definir las variables parsedStartDate y parsedEndDate
    const parsedStartDate = startDate
      ? parse(startDate, "yyyy-MM-dd", new Date())
      : null;
    const parsedEndDate = endDate
      ? parse(endDate, "yyyy-MM-dd", new Date())
      : null;

    // Filtrar por fechas si se ingresan fechas
    const filteredClientsByDate =
      startDate && endDate
        ? clients.filter((client) => {
            const clientDate = parse(
              client.date.substring(0, 10),
              "dd/MM/yyyy",
              new Date()
            );

            return clientDate >= parsedStartDate && clientDate <= parsedEndDate;
          })
        : clients;

    // Filtrar por nombre del servicio si se ingresa un término de búsqueda
    const filteredClientsByService = searchTermService
      ? filteredClientsByDate.filter((client) => {
          return client.nombreServicio
            .toLowerCase()
            .includes(searchTermService.toLowerCase());
        })
      : filteredClientsByDate;

    // Filtrar por nombre del cliente si se ingresa un término de búsqueda
    const filteredClientsByName = searchTermClient
      ? filteredClientsByService.filter((client) => {
          return client.nombreTransportador
            .toLowerCase()
            .includes(searchTermClient.toLowerCase());
        })
      : filteredClientsByService;

    // Actualizar el estado clients con los resultados del filtro
    setClients(filteredClientsByName);
    if (searchTermService === "" && searchTermClient === "") {
      setIsSearching(false);
    }
  };

  const clearFilter = () => {
    setClients([...originalClients]);
    setIsSearching(false); // Restablecer isSearching a false
    // Restablecer los campos de búsqueda
    setSearchTermService("");
    setSearchTermClient("");
    setStartDate("");
    setEndDate("");
  };

  const toggleSortOrder = () => {
    // Cambiar el orden al contrario del orden actual
    setIsDescendingOrder(!isDescendingOrder);

    // Ordenar la lista de clientes en consecuencia
    const sortedForms = [...clients].sort((a, b) => {
      const dateA = parse(a.date, "dd/MM/yyyy HH:mm:ss", new Date()).getTime();
      const dateB = parse(b.date, "dd/MM/yyyy HH:mm:ss", new Date()).getTime();

      return isDescendingOrder ? dateB - dateA : dateA - dateB;
    });
    // Actualizar la lista de clientes con el nuevo orden
    setClients(sortedForms);
  };

  const downloadImageAsPDF = (imageUrl, fileName) => {
    const pdf = new jsPDF();
    const img = new Image();
    img.src = imageUrl;

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * pdfWidth) / img.width;

      pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${fileName}.pdf`);
    };
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Configurar la orientación como horizontal
      fontSize: 6,
    });

    doc.autoTable({
      cellStyles: {
        // Permite el ajuste automático del texto dentro de las celdas
        cellWidth: "wrap",
      },
      head: [
        [
          "Nombre Transportador ",
          "Num Cedula",
          "Nombre Empresa",
          "Nombre Servicio",
          "Hora-Inicio",
          "Hora-Despacho",
          "Hora-Final",
          "Devoluciones",
          "Observacion Devoluciones",
          "Tipo de servicio ",
          "Liquidacion ",
          "Observaciones ",
          "Nombre Receptor",
        ],
      ],
      body: clients.map((client) => [
        client.nombreTransportador,
        client.numCedula,
        client.nombreEmpresa,
        client.nombreServicio,
        client.horaInicio,
        client.horaDespacho,
        client.horaFinal,
        client.devoluciones,
        client.observacionDevoluciones,
        client.tipodeservicio,
        client.liquidacion,
        client.observaciones,
        client.nombreReceptor,
      ]),
    });
    // Cargar la imagen de la marca de agua
    const watermarkImg = new Image();
    watermarkImg.src = logo; // Asignar la variable logo como fuente

    // Esperar a que se cargue la imagen antes de continuar
    watermarkImg.onload = function () {
      // Agregar la imagen de la marca de agua en la esquina inferior derecha
      const logoWidth = 50; // Ancho deseado del logo
      const logoHeight = (logoWidth / watermarkImg.width) * watermarkImg.height;

      const xPosLogo = doc.internal.pageSize.getWidth() - logoWidth - 10; // Ajusta el margen derecho
      const yPosLogo = doc.internal.pageSize.getHeight() - logoHeight - 10; // Ajusta el margen inferior

      doc.addImage(
        watermarkImg,
        "PNG",
        xPosLogo,
        yPosLogo,
        logoWidth,
        logoHeight
      );

      // Ajustar el tamaño de fuente para el texto adicional
      doc.setFontSize(14); // Puedes ajustar el tamaño según tus necesidades

      // Agregar texto en la parte inferior izquierda
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();

      doc.text(
        "Historico de reportes de entrega",
        10,
        doc.internal.pageSize.getHeight() - 20
      );
      doc.text(
        `Generado el: ${formattedDate}`,
        10,
        doc.internal.pageSize.getHeight() - 10
      );

      // Restaurar el tamaño de fuente original
      doc.setFontSize(6);

      // Guardar el documento
      doc.save("Historico_reportes.pdf");
    };
  };

  const handleEdit = (client) => {
    // Set the client data to be edited
    setEditingClient(client);
    setOpen(true);
  };

  const handleSave = () => {
    // Make a PUT request to update the client data in the backend
    fetch(API_URL + `guide/clients/${editingClient.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editingClient),
    })
      .then((response) => {
        if (response.ok) {
          // If the update is successful, close the edit dialog and update the client data in the state
          setOpen(false);
          const updatedClients = clients.map((client) =>
            client.id === editingClient.id
              ? { ...client, ...editingClient }
              : client
          );
          setClients(updatedClients);
          setEditingClient(null);
        }
      })
      .catch((error) => {
        console.error("Error updating client data:", error);
      });
  };

  const handleClose = () => {
    // Close the edit dialog without saving changes
    setOpen(false);
    setEditingClient(null);
  };

  const formStyle = {
    padding: "15px",
    marginBottom: "10px",
  };

  return (
    <>
      <h1> Historico de reportes de entrega</h1>
      <div style={formStyle}>
        <form onSubmit={handleFilter}>
          <TextField
            type="text"
            label="Buscar por nombre del servicio"
            value={searchTermService}
            onChange={(e) => setSearchTermService(e.target.value)}
            disabled={isSearching}
          />

          <TextField
            type="text"
            label="Buscar por nombre del transportador"
            value={searchTermClient}
            onChange={(e) => setSearchTermClient(e.target.value)}
            disabled={isSearching}
          />

          <TextField
            type="date"
            label="Fecha de inicio"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            disabled={isSearching} // Deshabilita el campo cuando isSearching es true
          />
          <TextField
            type="date"
            label="Fecha de fin"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            disabled={isSearching} // Deshabilita el campo cuando isSearching es true
          />
          <Button type="submit" variant="contained" color="primary">
            Filtrar
          </Button>
          <Button variant="contained" color="secondary" onClick={clearFilter}>
            Borrar filtro
          </Button>
        </form>
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PdfIcon />}
        onClick={downloadPDF}
      >
        Descargar PDF
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="clients table">
          <TableHead>
            <TableRow>
              <TableCell>
                {/* Agregar un botón para cambiar el orden de fecha */}
                <Button
                  variant="text"
                  color="primary"
                  onClick={toggleSortOrder}
                >
                  Fecha {isDescendingOrder ? "▼" : "▲"}
                </Button>
              </TableCell>{" "}
              <TableCell>Nombre Servicio </TableCell>
              <TableCell>Nombre Transportador </TableCell>
              <TableCell>Num Cedula</TableCell>
              <TableCell>Nombre Empresa</TableCell>
              <TableCell>Descripcion Servicios</TableCell>
              <TableCell>Despacho</TableCell>
              <TableCell>Hora Inicio </TableCell>
              <TableCell>Hora Despacho</TableCell>
              <TableCell>Hora Final</TableCell>
              <TableCell>Devoluciones </TableCell>
              <TableCell>Observacion Devoluciones </TableCell>
              <TableCell>Albaran</TableCell>
              <TableCell>Tipo de servicio </TableCell>
              <TableCell>Liquidacion</TableCell>
              <TableCell>Observaciones</TableCell>
              <TableCell>Nombre Receptor</TableCell>
              <TableCell>Facturas </TableCell>
              <TableCell>Foto receptor </TableCell>
              <TableCell> Editar </TableCell>
              <TableCell>Eliminar Legalizacion </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell>{client.date}</TableCell>
                <TableCell>{client.nombreServicio}</TableCell>
                <TableCell>{client.nombreTransportador}</TableCell>
                <TableCell>{client.numCedula}</TableCell>
                <TableCell>{client.nombreEmpresa}</TableCell>
                <TableCell>{client.descripcionServicios}</TableCell>
                <TableCell>{client.despacho}</TableCell>
                <TableCell>{client.horaInicio}</TableCell>
                <TableCell>{client.horaDespacho}</TableCell>
                <TableCell>{client.horaFinal}</TableCell>
                <TableCell>{client.devoluciones}</TableCell>
                <TableCell>{client.observacionDevoluciones}</TableCell>
                <TableCell>{client.albaran}</TableCell>
                <TableCell>{client.tipodeservicio}</TableCell>
                <TableCell>{client.liquidacion}</TableCell>
                <TableCell>{client.observaciones}</TableCell>
                <TableCell>{client.nombreReceptor}</TableCell>
                <TableCell>
                  {client.facturaFile && (
                    <a
                      href={`data:application/pdf;base64,${client.facturaFile}`}
                      download={`factura_${client.id}.pdf`}
                    >
                      Descargar Factura
                    </a>
                  )}
                </TableCell>
                <TableCell>
                  {client.fotoReceptorFile && (
                    <a
                      href={`data:application/pdf;base64,${client.fotoReceptorFile}`}
                      download={`foto_receptor_${client.id}.pdf`}
                    >
                      Descargar Foto Receptor
                    </a>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(client)}
                  >
                    Editar
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDeleteLegalizacion(client.id)}
                  >
                    Eliminar Legalización
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Cliente</DialogTitle>
        <DialogContent>
          {editingClient && (
            <form>
              <TextField
                label="Nombre Transportador "
                value={editingClient.nombreTransportador}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    nombreTransportador: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Num Cedula"
                value={editingClient.numCedula}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    numCedula: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Nombre Empresa"
                value={editingClient.nombreEmpresa}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    nombreEmpresa: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Descripcion Servicios"
                value={editingClient.descripcionServicios}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    descripcionServicios: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Despacho"
                value={editingClient.despacho}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    despacho: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Hora Inicio "
                value={editingClient.horaInicio}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    horaInicio: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Hora Despacho"
                value={editingClient.horaDespacho}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    horaDespacho: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Hora Final"
                value={editingClient.horaFinal}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    horaFinal: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Devoluciones "
                value={editingClient.devoluciones}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    devoluciones: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Albaran"
                value={editingClient.albaran}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    albaran: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Tipo de servicio "
                value={editingClient.tipodeservicio}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    tipodeservicio: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Liquidacion "
                value={editingClient.liquidacion}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    liquidacion: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Nombre Receptor"
                value={editingClient.nombreReceptor}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    nombreReceptor: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConveyorTable;
