import React, { useEffect, useState } from "react";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { API_URL, showMessage } from "../util/util";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../images/icons/GETCAR_COLOR.png";
import { Button } from "react-bootstrap";

import "./ArchivosCliente.css";

const Archivos = () => {
  let navigate = useNavigate();
  const [clientLinks, setClientLinks] = useState([]);
  const signatureRef = useRef();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [contratoEnviado, setContratoEnviado] = useState(
    localStorage.getItem("contratoEnviado") === "true"
  );

  useEffect(() => {
    const authData = localStorage.getItem("authData");
    if (!authData) {
      navigate("/");
      showMessage(
        "Error",
        "Para acceder a los formularios, inicia sesión",
        "error",
        "Reintentar"
      );
    } else {
      const clientId = JSON.parse(authData).id; // Obtener el ID del cliente desde los datos de autenticación
      fetch(API_URL + `cliente/${clientId}/enlaces`)
        .then((response) => response.json())
        .then((data) => {
          setClientLinks(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const handleAcceptContract = () => {
    Swal.fire({
      title: "Cargando",
      html: '<div class="loader"></div><p>Enviando formulario...</p>',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    const signatureData = signatureRef.current.toDataURL();
    const signatureBlob = dataURItoBlob(signatureData);
    const signatureFile = new File([signatureBlob], "firma.png");
  
    const formData = new FormData();
    formData.append("EnlaceContrato", clientLinks[0]);
    formData.append("FirmaContrato", signatureFile);
  
    fetch(API_URL + "SignatureContract/SignatureContract", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Éxito",
            text: "El contrato se envió correctamente.",
          });
          setContratoEnviado(true);
          localStorage.setItem("contratoEnviado", "true");
          console.log(response);
        } else {
          throw new Error("Error en la solicitud");
        }
      })
      .catch((error) => {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo enviar el contrato.",
        });
        console.error(error);
      });
  };
  
  if (contratoEnviado) {
    showMessage(
      "Formulario completado",
      "Usted ya ha diligenciado este formulario.",
      "info",
      "Aceptar"
    );
    return null;
  }

  // Función auxiliar para convertir una cadena de base64 a un objeto Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleClearSignature = () => {
    signatureRef.current.clear(); // Limpia la firma del canvas
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
 <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Inicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Tu Aliado de confianza</h1>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
      </header>
      <main className="FirmaContrato">
      <div className="card-container">
        <h3>Enlaces del cliente</h3>
        <p>
          Por favor, lea el contrato y revise cuidadosamente la información
          antes de aceptar.
        </p>
        <ul className="links-list">
          {clientLinks.map((link, index) => {
            const formattedLink = link.replace(/^"(.*)"$/, "$1");
            return (
              <li key={index}>
                <a
                  href={formattedLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formattedLink}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="signature-container">
          <p>Firma virtual</p>
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              width: 400,
              height: 200,
              className: "signature-canvas",
            }}
          />
          <button className="btn btn-danger" onClick={handleClearSignature}>
            Borrar firma
          </button>
        </div>
        <div>
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          <label>
            Acepto los <a href="#">términos y condiciones</a>
          </label>
        </div>
        <button
          className={`btn btn-success ${!termsAccepted ? "disabled" : ""}`}
          onClick={handleAcceptContract}
          disabled={!termsAccepted}
        >
          Acepto el contrato
        </button>
      </div>
      </main>
    </div>
  );
};

export default Archivos;
