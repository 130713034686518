import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/icons/GETCAR_COLOR.png";
import logopequeño from "../images/inicial-07.png";

import Cordinadora from "../images/slider/Cordinadora.png";
import Deprisa from "../images/slider/Deprisa.png";
import Dhl from "../images/slider/Dhl.png";
import Envia from "../images/slider/Envia.png";
import FedEx from "../images/slider/FedEx.png";
import InterRapidisimo from "../images/slider/Inter Rapidisimo.png";
import MensajerosUrbanos from "../images/slider/Mensajeros urbanos..png";
import Moova from "../images/slider/Moova.png";
import Saferbo from "../images/slider/Saferbo.png";
import Tcc from "../images/slider/Tcc.png";
import EnlacesLogisticos from "../images/slider/Logo-enlaces-1.webp";

import Facebook from "../images/icons/facebook.png";
import Instagram from "../images/icons/instagram.png";
import Whatsapp from "../images/icons/whatsapp.png";
import Button from "react-bootstrap/Button";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_URL, showMessage } from "../util/util";
import "./Register.css";

export const Register = () => {
  const [emailExists, setEmailExists] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    lastName: "",
    birthDate: "",
    phone: "",
    identification:"",
    userType: "", // Agrega este campo para indicar si es cliente o conductor
  });
  
  const [isClient, setIsClient] = useState(false);
  const [isDriver, setIsDriver] = useState(false);

  const handleClientChange = () => {
    setIsClient(true);
    setIsDriver(false);
    setFormData((values) => ({ ...values, userType: "cliente" }));
  };
  
  const handleDriverChange = () => {
    setIsDriver(true);
    setIsClient(false);
    setFormData((values) => ({ ...values, userType: "conductor" }));
  };

  const [formCompleted, setFormCompleted] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  // Agregar un estado para el temporizador de debounce
  const [debounceTimer, setDebounceTimer] = useState(null);

  useEffect(() => {
    // Cancelar el temporizador anterior si existe
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Establecer un nuevo temporizador
    const newDebounceTimer = setTimeout(() => {
      if (formData.email) {
        setEmailExists(false); // Resetear el estado al iniciar la verificación
        checkEmailExists();
      }
    }, 500); // Cambiar el valor de tiempo según tus necesidades

    // Guardar el nuevo temporizador
    setDebounceTimer(newDebounceTimer);

    // Limpieza del temporizador si el componente se desmonta o si formData.email cambia
    return () => {
      clearTimeout(newDebounceTimer);
    };
  }, [formData.email]);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      formData.email !== "" &&
      formData.password !== "" &&
      formData.name !== "" &&
      formData.lastName !== "" &&
      formData.birthDate !== "" &&
      formData.phone !== "" &&
      formData.identification !== "" &&
      formData.userType !== "" // Asegúrate de que userType esté seleccionado
    ) {
      // Check if email exists before submitting the form
      if (emailExists) {
        showMessage("Error!", "Este correo electrónico ya está registrado.", "error", "Reintentar");
        return;
      }
  
      setFormCompleted(true);
      showMessage(
        "Success!",
        "Su Registro fue enviado, Por favor llenar el siguiente formulario para validar su activación",
        "success",
        "Continuar"
      );
      
      const response = await sendClientApi();
      console.log(`response`, response);
      console.log(formData);
  
      if (isClient) {
        navigate("/FormularioCliente");
      } else if (isDriver) {
        navigate("/Contrato");
      }
    } else {
      showMessage("Error!", "Llena todos los campos", "error", "Reintentar");
    }
  };
  

  const sendClientApi = async () => {
    const requestData = {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-type": "application/json",
      },
    };
    let response = await fetch(API_URL + "client", requestData);
    response = await response.json();
    return response;
  };

  const checkEmailExists = async () => {  
    try {
      const response = await fetch(`${API_URL}client/check-email?email=${formData.email}`);
      const data = await response.json();
      setEmailExists(data.exists);
    } catch (error) {
      console.error("Error checking email existence:", error);
    }
  };
  

  return (
    <div>
      <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Inicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Tu Aliado de confianza</h1>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="/">
                    Inicio
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Registro">
                    Registro usuarios
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Login">
                    Portal GetCar
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <div class="container">
          <div class="row px-3">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto px-0">
              <div class="img-left-register d-none d-md-flex"></div>

              <div class="card-body">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="text"
                      placeholder="Nombres"
                      name="name"
                      onChange={handleChange}
                      maxLength={20}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicLastName">
                    <Form.Control
                      type="text"
                      placeholder="Apellidos"
                      name="lastName"
                      onChange={handleChange}
                      maxLength={20}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicIdentification">
                    <Form.Control
                      type="text"
                      placeholder="Numero de cedula"
                      name="identification"
                      onChange={handleChange}
                      maxLength={15}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicBirhtDate">
                    <h4>Fecha de nacimiento</h4>
                    <Form.Control
                      type="date"
                      placeholder="Fecha de nacimiento"
                      name="birthDate"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPhone">
                    <Form.Control
                      type="text"
                      placeholder="Teléfono de contacto"
                      name="phone"
                      onChange={handleChange}
                      maxLength={15}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Ingresa tu correo"
                      name="email"
                      onChange={handleChange}
                      maxLength={35}
                    />
                    <Form.Text className="text-muted">
                      No compartiremos el email con nadie más
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Registra tu contraseña"
                      name="password"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Cliente"
                      name="isClient"
                      checked={isClient}
                      onChange={handleClientChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Conductor"
                      name="isDriver"
                      checked={isDriver}
                      onChange={handleDriverChange}
                    />
                  </Form.Group>
                  <Button variant="btn btn-success" type="submit">
                    Registrar
                  </Button>
                </Form>
                <div className="flex text-center-button">
                  <a href="/Login">Ya tengo una cuenta</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer class="pie-pagina">
        <div class="grupo-1">
          <div class="box1">
            <h2>Alianza estratégica con</h2>
            <img src={EnlacesLogisticos} alt="" width="250px" />
            <h2>Y el respaldo de </h2>
            <div class="sliderFotter">
              <div class="slide-track-Fotter">
                <div class="slideFotter">
                  <img src={Cordinadora} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Deprisa} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Dhl} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Envia} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={FedEx} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={InterRapidisimo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={MensajerosUrbanos} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Moova} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Saferbo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Tcc} alt=""></img>
                </div>

                <div class="slideFotter">
                  <img src={Cordinadora} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Deprisa} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Dhl} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Envia} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={FedEx} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={InterRapidisimo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={MensajerosUrbanos} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Moova} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Saferbo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Tcc} alt=""></img>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="contact-info-footer">
              <h3>Contacto</h3>
              <p>
                <FaMapMarkerAlt className="icon" /> Direccion: Ciudad principal
                : Bogotá-Colombia
              </p>
              <p>
                <FaEnvelope className="icon" /> Correo electrónico:
                GetCar@gmail.com
              </p>
              <p>
                <FaPhone className="icon" /> +57 3212827595
              </p>
              <div class="red-social">
                <a href="https://es-la.facebook.com/GetcarLogistica/">
                  <img src={Facebook} alt="" width="40px" />
                </a>
                <a href="https://www.instagram.com/getcar_power/">
                  <img src={Instagram} alt="" width="40px" />
                </a>
                <a href="https://wa.me/message/HFB3FNAT67UPJ1">
                  <img src={Whatsapp} alt="" width="40px" />
                </a>
              </div>
            </div>

            <ul class="footer-links">
              <h3>Enlaces</h3>
              <li>
                <a href="#">Inicio</a>
              </li>
              <li>
                <a href="/Registro">Registro usuarios</a>
              </li>
              <li>
                <a href="/Login">Portal GetCar</a>
              </li>
            </ul>
            <div class="quienes-somos">
              <h3>Quiénes somos</h3>
              <p>
                Somos aliados en transporte de carga y mensajería para mipymes
                colombianas. Soluciones logísticas flexibles y seguras a nivel
                nacional.
              </p>
            </div>
          </div>
        </div>
        <div class="grupo-2">
          <small>
            <img src={logopequeño} width="15px" height="15px" />
            |&copy;2023 - Todos los derechos reservados.
          </small>
        </div>
      </footer>
    </div>
  );
};
