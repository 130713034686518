import React from 'react';
import WhatsApp from "../images/icons/whatsapp.png";

import "./WhatsAppLogo.css"

const WhatsAppLogo = () => {
  return (
    <a href="https://wa.me/message/HFB3FNAT67UPJ1" target="_blank" rel="noopener noreferrer" className="whatsapp-logo">
      <img src={WhatsApp} alt="WhatsApp Logo" />
    </a>
  );
}

export default WhatsAppLogo;
