import React from "react";
import { useState, useEffect } from "react";
import { API_URL } from "../util/util";
import { Button } from "react-bootstrap";
import { showMessage } from "../util/util";
import { useNavigate } from "react-router-dom";
import logo from "../images/icons/GETCAR_COLOR.png";
import { PDFDocument } from "pdf-lib";
import Swal from "sweetalert2";
import Modal from "react-modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Importa los estilos CSS necesarios
import { isSameDay } from "date-fns";
import { parse } from "date-fns";

import "./SolicitudServicios.css";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    maxHeight: "80%",
    overflow: "auto",
    zIndex: 9999,
  },
};

const Servicios = () => {
  const [clientId, setClientId] = useState(null); // Estado para mantener el ID del cliente
  const [clientForms, setClientForms] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [eventData, setEventData] = useState([]);
  const [showObservationModal, setShowObservationModal] = useState(false);
  const [selectedObservation, setSelectedObservation] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [adjuntarOrdenCompra, setAdjuntarOrdenCompra] = useState(false);
  const [selectedFacturas, setSelectedFacturas] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNombreServicioEditable, setIsNombreServicioEditable] =
    useState(true);
  const [formData, setFormData] = useState({});
  const authData = localStorage.getItem("authData");

  useEffect(() => {
    if (!authData) {
      navigate("/");
      showMessage(
        "Error",
        "Para acceder a los formularios, inicia sesión",
        "error",
        "Reintentar"
      );
    } else {
      const parsedAuthData = JSON.parse(authData);
      setClientId(parsedAuthData.id); // Establecer el ID del cliente en el estado
      fetchClientForms(parsedAuthData.id);

      // Nueva solicitud Fetch para obtener datos del cliente
      fetch(API_URL + `client/${parsedAuthData.id}/full-info`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Error al obtener datos del cliente");
          }
        })
        .then((data) => {
          // Actualiza el estado formData con los datos del cliente
          setFormData({
            ...formData,
            EmpresaCliente: data.name, // Ajusta esto según la estructura de tus datos
            NitCedula: data.identification, // Ajusta esto según la estructura de tus datos
            Direccion: data.address, // Ajusta esto según la estructura de tus datos
            Telefono: data.phone, // Ajusta esto según la estructura de tus datos
            // Agrega más campos según sea necesario
          });
          setClientData(data); // Almacena los datos del cliente en el estado
        })
        .catch((error) => {
          console.error("Error al obtener datos del cliente:", error);
        });
    }
  }, []);

  const fetchClientForms = async (clientId) => {
    try {
      const response = await fetch(
        API_URL + `service/clients/${clientId}/forms`
      );
      if (response.ok) {
        const forms = await response.json();
        const events = forms.map((form) => ({
          date: parse(form.date, "dd/MM/yyyy HH:mm:ss", new Date()), // Ajusta cómo acceder a la fecha en tus datos
          IdServicio: form.id,
          NombreServicio: form.nombreServicio,
          EmpresaCliente: form.empresaCliente,
          FechaServicio: form.date,
          NombreAsesor: form.nombreVendedor,
          Servicio: form.servicio,
          TipoServicio: form.tipoServicio,
          HoraInicio: form.horaInicio,
          HoraFinal: form.horaFinal,
          observaciones: form.observaciones,
        }));

        console.log(events); // Verifica si los eventos se están procesando correctamente

        setEventData(events);
        setClientForms(forms);
      } else {
        console.error("Error al obtener los formularios");
      }
    } catch (error) {
      console.error("Error al obtener los formularios:", error);
    }
  };

  const handleServiceSelect = (selectedService) => {
    if (selectedService) {
      // Actualiza el estado del formulario con los datos del servicio seleccionado
      setFormData({
        NombreServicio: selectedService.nombreServicio,
        EmpresaCliente: clientData.name, // Empresa o nombre del cliente
        NitCedula: clientData.identification, // NIT o cédula del cliente
        Direccion: clientData.address, // Dirección del cliente
        Telefono: clientData.phone, // Teléfono del cliente
        Ciudad: selectedService.ciudad,
        NombreVendedor: selectedService.nombreVendedor,
        Servicio: selectedService.servicio,
        vehiculoRefrigerado: selectedService.vehiculoRefrigerado,
        TipoServicio: selectedService.tipoServicio,
        HoraInicio: selectedService.horaInicio,
        HoraFinal: selectedService.horaFinal,
        NombreConductor: selectedService.nombreConductor,
        NumCedula: selectedService.numCedula,
        Celular: selectedService.celular,
        DireccionVehiculo: selectedService.direccionVehiculo,
        PesoCarga: selectedService.pesoCarga,
        observaciones: selectedService.observaciones,
        Pago: selectedService.pago,
      });
      setIsNombreServicioEditable(false);
    } else {
      // Restablecer campos
      setFormData({
        ...formData,
        NombreServicio: "",
        EmpresaCliente: "",
        NitCedula: "",
        Direccion: "",
        Telefono: "",
        FechaServicio: "",
        Ciudad: "",
        NombreVendedor: "",
        Servicio: "",
        vehiculoRefrigerado: "",
        TipoServicio: "",
        HoraInicio: "",
        HoraFinal: "",
        NombreConductor: "",
        NumCedula: "",
        Celular: "",
        DireccionVehiculo: "",
        PesoCarga: "",
        observaciones: "",
        Pago: "",
      });
      setIsNombreServicioEditable(true);
    }
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const handleToggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleOpenObservationModal = (observation) => {
    setSelectedObservation(observation);
    setShowObservationModal(true);
  };
  const getFormattedServiceDetails = () => {
    const {
      IdServicio,
      NombreServicio,
      FechaServicio,
      NombreAsesor,
      Servicio,
      TipoServicio,
      HoraInicio,
      HoraFinal,
      observaciones,
    } = selectedObservation;

    return `
    *ID DEL SERVICIO:* ${IdServicio}
    *NOMBRE DEL SERVICIO:* ${NombreServicio}
    *FECHA DE SERVICIO:* ${FechaServicio}
    *NOMBRE DEL VENDEDOR:* ${NombreAsesor}
    *SERVICIO:* ${Servicio}
    *TIPO DE SERVICIO:* ${TipoServicio}
    *HORA DE INICIO:* ${HoraInicio}
    *HORA DE FINALIZACIÓN:* ${HoraFinal}
    *OBSERVACIONES:* ${observaciones}
  `;
  };

  const handleCopyToClipboard = () => {
    // Crear un elemento de entrada de texto invisible
    const textField = document.createElement("textarea");
    textField.innerText = getFormattedServiceDetails(); // Define una función para obtener los detalles formateados del servicio

    // Agregar el elemento al DOM
    document.body.appendChild(textField);

    // Seleccionar el texto en el elemento de entrada de texto
    textField.select();
    textField.setSelectionRange(0, 99999); // Para navegadores móviles

    // Copiar el texto al portapapeles
    document.execCommand("copy");

    // Eliminar el elemento de entrada de texto
    document.body.removeChild(textField);

    // Mostrar una notificación o mensaje al usuario
    alert("¡Los detalles del servicio se copiaron al portapapeles!");
  };

  let navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "file") {
      const file = event.target.files[0];
      setFormData((prevFormData) => ({ ...prevFormData, [name]: file }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const convertFacturasToPDF = async (facturaFiles) => {
    const pdfDoc = await PDFDocument.create();

    for (const facturaFile of facturaFiles) {
      if (facturaFile.type === "application/pdf") {
        // Si es un PDF, combínalo con el documento existente
        const pdfBytes = await fetch(URL.createObjectURL(facturaFile)).then(
          (res) => res.arrayBuffer()
        );
        const existingPdfDoc = await PDFDocument.load(pdfBytes);
        const pages = await pdfDoc.copyPages(
          existingPdfDoc,
          existingPdfDoc.getPageIndices()
        );

        pages.forEach((page) => {
          pdfDoc.addPage(page);
        });
      } else {
        // Si es una imagen, agrega una nueva página al PDF con la imagen
        const page = pdfDoc.addPage([612, 792]); // Tamaño de la página en puntos
        const imageBytes = await fetch(URL.createObjectURL(facturaFile)).then(
          (res) => res.arrayBuffer()
        );
        const image = await pdfDoc.embedJpg(imageBytes);

        // Obtén las dimensiones de la imagen
        const { width, height } = image.scale(1); // Sin escalar (tamaño original)

        // Ajusta el tamaño de la imagen para que llene toda la página
        page.drawImage(image, {
          x: 0,
          y: 0, // Coloca la imagen en la esquina superior izquierda
          width: 612, // Ancho de la página
          height: 792, // Alto de la página
        });
      }
    }

    // Convierte el PDF combinado a un Blob
    const combinedPDFBlob = new Blob([new Uint8Array(await pdfDoc.save())], {
      type: "application/pdf",
    });

    return combinedPDFBlob;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();

      // Agregar el ID del cliente al formData
      formDataToSend.append("clientId", clientId);

      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const facturasPDF = await convertFacturasToPDF(selectedFacturas);
      if (facturasPDF) {
        formDataToSend.append("ordenCompraFile", facturasPDF, "facturas.pdf");
      }

      try {
        Swal.fire({
          title: "Cargando",
          html: '<div class="loader"></div><p>Enviando formulario...</p>',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(API_URL + "service/service", {
          method: "POST",
          body: formDataToSend,
        });

        Swal.close();

        if (response.ok) {
          showMessage(
            "success!",
            "Viaje solicitado, a continuacion seleccione su servicio y envielo a su asesor ",
            "success",
            "Continuar"
          );
          // Recarga la página después de 2 segundos (o el tiempo que desees)
          setTimeout(() => {
            window.location.reload();
          }, 5000); // 2000 milisegundos = 2 segundos
        } else {
          showMessage(
            "Error",
            "Error al enviar el formulario",
            "error",
            "Aceptar"
          );
        }
      } catch (error) {
        console.error("Error al enviar el formulario", error);
        showMessage(
          "Error",
          "Error al enviar el formulario: " + error.message,
          "error",
          "Aceptar"
        );
      }
    } catch (error) {
      console.error("Error al procesar el formulario", error);
    }
  };

  useEffect(() => {
    // Este efecto se ejecutará después de que la página se haya recargado
    setShowCalendar(true); // Abre el calendario
  }, []);

  return (
    <div>
      <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Servicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Guía solicitud de servicios</h1>
          </div>
        </nav>
      </header>
      <div className="container-service">
        <Button onClick={handleToggleModal}>Agregar Servicios</Button>
        <Button onClick={handleToggleCalendar}>Mostrar Calendario</Button>

        <Modal
          isOpen={showModal}
          onRequestClose={handleToggleModal}
          style={modalStyles}
        >
          <h2>Solicitud de servicios</h2>
          <h4>
            Seleccione un servicio existente para autocompletar los espacios de
            lo contrario complete el formulario
          </h4>

          <select
            onChange={(e) => handleServiceSelect(JSON.parse(e.target.value))}
          >
            <option value="">Seleccionar un servicio existente</option>
            {Array.from(new Set(clientForms.map((form) => form.nombreServicio))) // Elimina duplicados
              .map((nombreServicio) => {
                const form = clientForms.find(
                  (form) => form.nombreServicio === nombreServicio
                ); // Encuentra el primer formulario con el nombre de servicio
                return (
                  <option key={form.id} value={JSON.stringify(form)}>
                    {form.nombreServicio}
                  </option>
                );
              })}
          </select>

          <form onSubmit={handleSubmit} className="custom-form">
            <input
              type="text"
              name="NombreServicio"
              placeholder="Ingresa el nombre que quieres asignar al servicio"
              value={formData.NombreServicio}
              onChange={handleInputChange}
              maxLength={15}
              disabled={!isNombreServicioEditable} // Deshabilita el campo si !isNombreServicioEditable
            />

            <input
              type="text"
              name="EmpresaCliente"
              placeholder="Ingresa Tu nombre"
              value={formData.EmpresaCliente}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="NitCedula"
              placeholder="Ingresa el # de nit o cedula"
              value={formData.NitCedula}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="Direccion"
              placeholder="Ingresa tu direccion fisica"
              value={formData.Direccion}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="Telefono"
              placeholder="# Telefono"
              value={formData.Telefono}
              onChange={handleInputChange}
            />
            <p>Ingrese la fecha y hora del servicio</p>
            <input
              type="datetime-local"
              name="FechaServicio"
              value={formData.FechaServicio || ""}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="Ciudad"
              placeholder="Ciudad de residencia"
              value={formData.Ciudad}
              onChange={handleInputChange}
              maxLength={15}
            />
            <select
              name="NombreVendedor"
              value={formData.NombreVendedor}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar nombre del asesor</option>
              <option>Jorge Moreno</option>
              <option>Edgar Garcia </option>
              <option>Nicolas Lopez</option>
              <option>No tengo vendedor asignado </option>
            </select>
            <select
              name="Servicio"
              value={formData.Servicio}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar servicio</option>
              <option>Sencillo</option>
              <option>Mini van</option>
              <option>Motorizados</option>
              <option>Envio nacional o paqueteo</option>
            </select>
            <select
              name="vehiculoRefrigerado"
              value={formData.vehiculoRefrigerado}
              onChange={handleInputChange}
            >
              <option value="">Requiere vehículo refrigerado</option>
              <option>Si</option>
              <option>No</option>
            </select>
            <select
              name="TipoServicio"
              value={formData.TipoServicio}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar tipo de servicio</option>
              <option>Turno Completo</option>
              <option>Medio turno</option>
              <option>Servicio rapido</option>
              <option>Servicio Especial</option>
              <option>Envio nacional o paqueteo</option>
            </select>
            <p>Hora inicio</p>

            <input
              type="time"
              name="HoraInicio"
              value={formData.HoraInicio}
              onChange={handleInputChange}
            />
            <p>Hora final</p>

            <input
              type="time"
              name="HoraFinal"
              value={formData.HoraFinal}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="NombreConductor"
              placeholder="Ingresa el nombre del contacto responsable"
              value={formData.NombreConductor}
              onChange={handleInputChange}
              maxLength={20}
            />
            <input
              type="text"
              name="NumCedula"
              placeholder="# De cedula"
              value={formData.NumCedula}
              onChange={handleInputChange}
              maxLength={15}
            />
            <input
              type="text"
              name="Celular"
              placeholder="# de celular"
              value={formData.Celular}
              onChange={handleInputChange}
              maxLength={15}
            />
            <input
              type="text"
              name="DireccionVehiculo"
              placeholder="Direccion Recogida"
              value={formData.DireccionVehiculo}
              onChange={handleInputChange}
              maxLength={25}
            />
            <input
              type="text"
              name="PesoCarga"
              placeholder="Peso total de la carga a transportar, en kilos"
              value={formData.PesoCarga}
              onChange={handleInputChange}
              maxLength={10}
            />
            <textarea
              name="observaciones"
              placeholder="Escribe aquí las observaciones"
              value={formData.observaciones}
              onChange={handleInputChange}
              maxLength={100}
            />
            <select
              name="Pago"
              value={formData.Pago}
              onChange={handleInputChange}
            >
              <option value="">Seleccionar tipo de pago</option>
              <option>Credito</option>
              <option>De contado</option>
            </select>
            <label>
              Adjuntar Orden de Compra:
              <input
                type="checkbox"
                name="adjuntarOrdenCompra"
                checked={adjuntarOrdenCompra}
                onChange={(e) => setAdjuntarOrdenCompra(e.target.checked)}
              />
            </label>

            {adjuntarOrdenCompra && (
              <div>
                <p>
                  {" "}
                  Puede adjuntar todas las fotos de la orden de compra. Por
                  favor, adjunte solo archivos de imagen.
                </p>
                <label for="facturas">Adjuntar orden de compra </label>
                <input
                  type="file"
                  id="ordenCompraFile"
                  name="ordenCompraFile"
                  accept=".pdf, .jpg, .jpeg"
                  onChange={(e) =>
                    setSelectedFacturas(Array.from(e.target.files))
                  }
                  multiple // Permite seleccionar múltiples archivos
                  max-size="20971520"
                />
              </div>
            )}

            <div className="button-group">
              <button type="submit" className="submit-button">
                Guardar
              </button>
              <button onClick={handleToggleModal} className="cancel-button">
                Cancelar
              </button>
            </div>
          </form>
        </Modal>

        {showCalendar && (
          <div className="calendar-container">
            <Calendar
              tileContent={({ date, view }) => {
                if (view === "month") {
                  const eventsOnDay = eventData.filter((event) =>
                    isSameDay(event.date, date)
                  );

                  if (eventsOnDay.length > 0) {
                    return (
                      <div>
                        {eventsOnDay.map((event, index) => (
                          <button
                            key={index}
                            className="observation-button yellow-bg"
                            onClick={() => handleOpenObservationModal(event)}
                          >
                            {event.NombreServicio}
                          </button>
                        ))}
                      </div>
                    );
                  }
                }
                return null;
              }}
            />
          </div>
        )}
        {showObservationModal && (
          <Modal
            isOpen={showObservationModal}
            onRequestClose={() => setShowObservationModal(false)}
            style={modalStyles}
          >
            <h2>Datos</h2>
            <p>Id del Servicio: {selectedObservation.IdServicio}</p>
            <p>Nombre del Servicio: {selectedObservation.NombreServicio}</p>
            <p>Fecha de Servicio: {selectedObservation.FechaServicio}</p>
            <p>Nombre de Vendedor: {selectedObservation.NombreAsesor}</p>
            <p>Servicio: {selectedObservation.Servicio}</p>
            <p>Tipo de Servicio: {selectedObservation.TipoServicio}</p>
            <p>Hora Inicio: {selectedObservation.HoraInicio}</p>
            <p>Hora Final: {selectedObservation.HoraFinal}</p>
            <p>observaciones: {selectedObservation.observaciones}</p>

            <Button onClick={handleCopyToClipboard}>
              Copiar al portapapeles
            </Button>

            <Button onClick={() => setShowObservationModal(false)}>
              Cerrar
            </Button>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Servicios;
