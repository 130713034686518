import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, showMessage } from "../util/util";
import {
  RiCamera2Fill,
  RiUserFill,
  RiLogoutCircleLine,
  RiArrowDownSLine,
} from "react-icons/ri";
import {
  FaHome,
  FaEnvelope,
  FaDollarSign,
  FaFile,
  FaCalendar,
} from "react-icons/fa"; // Importar los iconos que necesitas
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./NavBarLateral.css";

function BarraLateral() {
  let navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [lastLoginTime, setLastLoginTime] = useState(null); // Inicializa en null
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isLegalizationMenuOpen, setLegalizationMenuOpen] = useState(false);

  useEffect(() => {
    const lastLoginTimeString = localStorage.getItem("lastLoginTime");
    if (lastLoginTimeString) {
      const lastLoginDate = new Date(lastLoginTimeString);
      setLastLoginTime(lastLoginDate);
    }
  }, []);

  useEffect(() => {
    const authData = localStorage.getItem("authData");
    if (!authData) {
      navigate("/");
      showMessage(
        "Error",
        "Para acceder a los formularios, inicia sesión",
        "error",
        "Reintentar"
      );
    } else {
      const clientId = JSON.parse(authData).id; // Obtener el ID del cliente desde los datos de autenticación
      fetch(API_URL + `client/${clientId}/info`)
        .then((response) => response.json())
        .then((data) => {
          setClientInfo(data);
        })
        .catch((error) => {
          console.error("Error al obtener la información del cliente:", error);
        });
    }
  }, []);

  const handleFileUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file);
        uploadProfilePhoto(file);
      }
    };
    fileInput.click();
  };

  const uploadProfilePhoto = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(API_URL + `client/${clientInfo.id}/upload-profile-photo`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setClientInfo(data);
        window.location.reload(); // Recargar la página
      })
      .catch((error) => {
        console.error("Error al subir la imagen de perfil:", error);
      });
  };

  const renderProfilePhoto = () => {
    if (clientInfo && clientInfo.profilePhotoBase64) {
      return (
        <div className="profile-photo">
          <img
            src={`data:image/jpeg;base64,${clientInfo.profilePhotoBase64}`}
            alt="Foto de perfil"
          />
          <div className="upload-overlay">
            <div className="upload-icon" onClick={handleFileUpload}>
              <RiCamera2Fill />
            </div>
            <div className="upload-text">Subir imagen</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="profile-photo">
          <div className="user-icon">
            <RiUserFill />
          </div>
          <div className="upload-overlay">
            <div className="upload-icon" onClick={handleFileUpload}>
              <RiCamera2Fill />
            </div>
            <div className="upload-text">Subir imagen</div>
          </div>
        </div>
      );
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    setLegalizationMenuOpen(false); // Cerrar el menú de legalización al hacer clic en otros elementos del menú principal
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <Navbar
      expand="md"
      variant="light"
      className="col-md-3 col-lg-2 d-md-block sidebar custom-navbar"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu} />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={`${isMenuOpen ? "show" : ""}`}
      >
        <Nav className="flex-column">
          {clientInfo ? (
            <div className="profile">
              {renderProfilePhoto()}
              <h3>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/Perfil")} // Navegar a la página de perfil al hacer clic en el nombre
                >
                  {clientInfo.name}
                </span>
              </h3>{" "}
              <p>ID: {clientInfo.id}</p>
              <p>Rol: {clientInfo.userType}</p>
              {lastLoginTime && (
                <p>Última sesión: {lastLoginTime.toLocaleString()}</p>
              )}{" "}
            </div>
          ) : (
            <p>Cargando información del cliente...</p>
          )}
          <div className="menu-box">
            <Nav.Link href="/Formularios">
              <FaHome /> Inicio
            </Nav.Link>
          </div>
          <div className="menu-box">
            <NavDropdown
              title={
                <>
                  <FaFile className="white-icon" />{" "}
                  {/* Agregar el icono de la hoja */}
                  <span>
                    Formularios
                    <RiArrowDownSLine
                      className={`arrow-icon ${
                        isLegalizationMenuOpen ? "active" : ""
                      }`}
                    />
                  </span>
                </>
              }
              id="formularios-dropdown"
            >
              <NavDropdown.Item href="/ServiciosTransporte">
                Legalizacion de servicios
              </NavDropdown.Item>
              <NavDropdown.Item href="/SolicitudServicios">
                Solicitud de servicios
              </NavDropdown.Item>
            </NavDropdown>
          </div>
          {clientInfo && (
            <div className="menu-box">
              <NavDropdown
                title={
                  <>
                    <FaCalendar className="white-icon" />
                    <span>
                      Históricos
                      <RiArrowDownSLine
                        className={`arrow-icon ${
                          isDropdownOpen ? "active" : ""
                        }`}
                      />
                    </span>
                  </>
                }
                id="historicos-dropdown"
              >
                <NavDropdown.Item href="/HistoricoViajesCliente">
                  Viajes solicitados
                </NavDropdown.Item>
                {clientInfo.userType === "cliente" ? (
                  <NavDropdown.Item href="/HistoricoReportesCliente">
                    Reportes de entrega 
                  </NavDropdown.Item>
                ) : (
                  <NavDropdown.Item href="/HistoricoReportesConductor">
                    Reportes de entrega 
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            </div>
          )}
             <div className="menu-box">
            <Nav.Link href="/Rastreo">
              <FaDollarSign className="white-icon" />
              Rastreo de guias
            </Nav.Link>
          </div>
          <div className="menu-box">
            <Nav.Link href="/Construccion">
              <FaDollarSign className="white-icon" />
              Estados de Cuenta y Soportes de Pago
            </Nav.Link>
          </div>
          <div className="menu-box">
            <Nav.Link href="/PQR">
              <FaEnvelope className="white-icon" />
              Reclamaciones, PQR y Solicitudes
            </Nav.Link>
          </div>
          <div className="logout">
            <Nav.Link onClick={logOut}>
              <RiLogoutCircleLine className="logout-icon" />
              Cerrar sesión
            </Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default BarraLateral;
