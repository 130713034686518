import { useState } from "react";
import logo from "../images/icons/GETCAR_COLOR.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, showMessage } from "../util/util";
import { Button } from "@mui/material";
import "./Admin.css";

document.addEventListener("DOMContentLoaded", function () {
  fetch(API_URL + "client/not-approved")
    .then((response) => response.json())
    .then((data) => {
      const notApprovedClientsTable = document.getElementById(
        "not-approved-clients-table"
      );
      data.forEach((client) => {
        const row = document.createElement("tr");
        row.innerHTML = `
          <td>${client.name}</td>
          <td>${client.lastName}</td>
          <td>${client.identification}</td>
          <td>${client.phone}</td>
          <td>${client.email}</td>
          <td>${client.userType}</td>
          <td><button class="approve-button" data-client-id="${client.id}">Aprobar</button></td>
          <td><button class="delete-button" data-client-id="${client.id}">Eliminar</button></td>
        `;
        notApprovedClientsTable.appendChild(row);
      });

      // Agregar listener para los botones "Aprobar"
      const approveButtons = document.querySelectorAll(".approve-button");
      approveButtons.forEach((button) => {
        button.addEventListener("click", function () {
          const clientId = this.getAttribute("data-client-id");
          approveClient(clientId);
        });
      });
      // Agregar listener para los botones "Eliminar"
      const deleteButtons = document.querySelectorAll(".delete-button");
      deleteButtons.forEach((button) => {
        button.addEventListener("click", function () {
          const clientId = this.getAttribute("data-client-id");
          handleDeleteClient(clientId);
        });
      });
    });
});

fetch(API_URL + "client/approved")
  .then((response) => response.json())
  .then((data) => {
    const approvedClientsTable = document.getElementById(
      "approved-clients-table"
    );
    data.forEach((client) => {
      const row = document.createElement("tr");
      row.innerHTML = `
      <td>${client.name}</td>
      <td>${client.lastName}</td>
      <td>${client.identification}</td>
      <td>${client.phone}</td>
      <td>${client.email}</td>
      <td>${client.userType}</td>
        <td>
          <input type="text" class="link-input" placeholder="Ingrese un enlace" style="width: 30%;" />
          <button class="add-link-button" data-client-id="${client.id}">Agregar Enlace</button>
          <button class="view-links-button" data-client-id="${client.id}">Ver Enlaces</button>
          <button class="delete-button" data-client-id="${client.id}">Eliminar</button>
        </td>
      `;
      approvedClientsTable.appendChild(row);
    });

    // Agregar listener para los botones "Ver Enlaces"
    const viewLinksButtons = document.querySelectorAll(".view-links-button");
    viewLinksButtons.forEach((button) => {
      button.addEventListener("click", function () {
        const clientId = this.getAttribute("data-client-id");
        viewClientLinks(clientId);
      });
    });

    // Agregar listener para los botones "Agregar Enlace"
    const addLinkButtons = document.querySelectorAll(".add-link-button");
    addLinkButtons.forEach((button) => {
      button.addEventListener("click", function () {
        const clientId = this.getAttribute("data-client-id");
        const linkInput = this.parentElement.querySelector(".link-input");
        const link = linkInput.value.trim();
        if (link) {
          addClientLink(clientId, link);
          linkInput.value = "";
        }
      });
      // Agregar listener para el botón "Cerrar" del modal
      const closeModalButton = document.getElementById("close-modal-button");
      closeModalButton.addEventListener("click", function () {
        const linksModal = document.getElementById("links-modal");
        linksModal.style.display = "none";
      });
      // Agregar listener para los botones "Eliminar"
      const deleteButtons = document.querySelectorAll(".delete-button");
      deleteButtons.forEach((button) => {
        button.addEventListener("click", function () {
          const clientId = this.getAttribute("data-client-id");
          handleDeleteClient(clientId);
        });
      });
    });
  });

// ...

function viewClientLinks(clientId) {
  fetch(API_URL + `client/${clientId}/links`)
    .then((response) => response.json())
    .then((data) => {
      const linksList = document.getElementById("client-links-list");
      linksList.innerHTML = ""; // Limpiar la lista antes de mostrar los enlaces

      data.forEach((link, index) => {
        const listItem = document.createElement("li");
        listItem.textContent = link;

        const deleteButton = document.createElement("button");
        deleteButton.textContent = "Eliminar";
        deleteButton.addEventListener("click", function () {
          deleteClientLink(clientId, index);
        });
        listItem.appendChild(deleteButton);
        linksList.appendChild(listItem);
      });

      // Mostrar el modal o sección que contiene la lista de enlaces
      const linksModal = document.getElementById("links-modal");
      linksModal.style.display = "block";
    })
    .catch((error) => console.error(error));
}

function addClientLink(clientId, link) {
  fetch(API_URL + `client/${clientId}/links`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(link),
  })
    .then((response) => response.text())
    .then((message) => {
      console.log(message);
    })
    .catch((error) => console.error(error));
}

function deleteClientLink(clientId, linkIndex) {
  fetch(API_URL + `client/${clientId}/links/${linkIndex}`, {
    method: "DELETE",
  })
    .then((response) => {
      if (response.ok) {
        console.log("Enlace eliminado exitosamente");
        // Actualizar la vista después de eliminar el enlace
        viewClientLinks(clientId);
      } else {
        console.error("Error al eliminar el enlace");
      }
    })
    .catch((error) => console.error(error));
}
function approveClient(clientId) {
  fetch(API_URL + `client/${clientId}/approve`, {
    method: "PUT",
  })
    .then(() => {
      // Actualizar la tabla de clientes después de aprobar
      window.location.reload();
    })
    .catch((error) => console.error(error));
}

const handleDeleteClient = (clientId) => {
  if (window.confirm("¿Estás seguro de que deseas eliminar este cliente?")) {
    fetch(API_URL + `client/${clientId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
        } else {
          console.error("Error al eliminar el cliente");
        }
      })
      .catch((error) => console.error(error));
  }
};

function Admin() {
  let navigate = useNavigate();

  useEffect(() => {
    const authDataAdmin = localStorage.getItem("authDataAdmin");
    if (!authDataAdmin) {
      navigate("/");
      showMessage(
        "Error!",
        " No tienes los permisos para acceder",
        "error",
        "Reintentar"
      );
    }
  }, []);

  const clearFilter = () => {
    // Recargar la página
    window.location.reload();
  };

  return (
    <div>
      <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Inicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Tu Aliado de confianza</h1>
          </div>
        </nav>
      </header>
      <Button variant="contained" color="secondary" onClick={clearFilter}>
        Mostrar clientes
      </Button>
      <div className="Main-Admin">
        <h1>Clientes no aprobados</h1>
        <table class="table table-success table-striped">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Cedula</th>
              <th>Celular</th>
              <th>Email</th>
              <th>Rol</th>
              <th>Acción</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody id="not-approved-clients-table"></tbody>
        </table>
        <h1>Clientes aprobados</h1>
        <table class="table table-success table-striped">
          <thead>
            <tr>
            <th>Nombre</th>
              <th>Apellido</th>
              <th>Cedula</th>
              <th>Celular</th>
              <th>Email</th>
              <th>Rol</th>
              <th>Enlace Contrato</th>
            </tr>
          </thead>
          <tbody id="approved-clients-table"></tbody>
        </table>
        <div id="links-modal" class="modal">
          <div class="modal-content">
            <span class="close">&times;</span>
            <h2>Enlaces del Cliente</h2>
            <ul id="client-links-list"></ul>
            <button id="close-modal-button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Admin;
