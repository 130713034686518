import React, { useState, useEffect } from "react";
import { API_URL } from "../util/util";
import { useNavigate } from "react-router-dom";
import "./PQRAdmin.css"; // Importa tu archivo de estilos CSS

const PQRAdmin = () => {
  const [pqrs, setPqrs] = useState([]);
  const [selectedPQR, setSelectedPQR] = useState(null);
  const [response, setResponse] = useState("");
  const [adjunto, setAdjunto] = useState(null); 

  const navigate = useNavigate();

  useEffect(() => {
    // Realiza la solicitud al servidor para obtener la lista de PQR
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL + "pqr/pqrs");
        if (response.ok) {
          const data = await response.json();
          setPqrs(data);
        } else {
          console.error("Error en la solicitud al servidor");
        }
      } catch (error) {
        console.error("Error de red:", error);
      }
    };

    fetchData();
  }, []); // Ejecuta la solicitud al cargar el componente

  const handleSelectPQR = (event) => {
    const selectedPQRId = event.target.value;
    const selectedPQRObject = pqrs.find((pqr) => pqr.id === selectedPQRId);
    setSelectedPQR(selectedPQRObject);
  };

  
  const handleAdjuntoChange = (event) => {
    const file = event.target.files[0];
    setAdjunto(file);
  };

  const handleResponseChange = (event) => {
    setResponse(event.target.value);
  };

  const handleRespond = async () => {
    try {
      const formData = new FormData();
      formData.append("pqrId", selectedPQR.id);
      formData.append("respuesta", response);
      formData.append("adjunto", adjunto); // Agregar archivo adjunto al FormData

      const serverResponse = await fetch(API_URL + "pqr/pqr/respond", {
        method: 'POST',
        body: formData,
      });

      if (serverResponse.ok) {
        // Actualizar la interfaz después de responder
        // Puedes realizar acciones adicionales aquí según la respuesta del servidor
      } else {
        console.error('Error en la solicitud al servidor');
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };

  const PQRFormReadOnly = ({ pqr }) => (
    <div className="form-container">
      <h2>PQR Seleccionado</h2>
      <p>Detalles del PQR:</p>
      <ul className="field-list">
        <li>
          <label>ID:</label>
          <span>{pqr.id}</span>
        </li>
        <li>
          <label>Nombre:</label>
          <span>{pqr.nombre}</span>
        </li>
        <li>
          <label>Correo Electrónico:</label>
          <span>{pqr.correo}</span>
        </li>
        <li>
          <label>Número de Teléfono:</label>
          <span>{pqr.numero}</span>
        </li>
        <li>
          <label>Tipo de PQR:</label>
          <span>{pqr.tipoPqr}</span>
        </li>
        <li>
          <label>Descripción:</label>
          <span>{pqr.descripcion}</span>
        </li>
        <li>
          <label>Fecha del Incidente:</label>
          <span>{pqr.fechaIncidente}</span>
        </li>
        <li>
          <label>Detalles Adicionales:</label>
          <span>{pqr.detalles}</span>
        </li>
        <li>
          <label>Acciones Deseadas:</label>
          <span>{pqr.acciones}</span>
        </li>
        {/* Agrega otros campos según tus necesidades */}
      </ul>
    </div>
  );

  return (
    <div>
      <h2>Lista de PQR</h2>
      <label>Seleccionar PQR:</label>
      <select className="select-field" onChange={handleSelectPQR}>
        <option value="">Seleccione un PQR...</option>
        {pqrs.map((pqr) => (
          <option key={pqr.id} value={pqr.id}>
            {pqr.nombre} - {pqr.tipoPqr}
          </option>
        ))}
      </select>

      {selectedPQR && <PQRFormReadOnly pqr={selectedPQR} />}

      <div>
      <label>Responder:</label>
          <textarea
            name="respuesta"
            value={response}
            onChange={handleResponseChange}
          ></textarea>

          <label>Adjuntar Archivo:</label>
          <input type="file" name="adjunto" onChange={handleAdjuntoChange} />

          <button onClick={handleRespond}>Enviar Respuesta</button>
        </div>
    </div>
  );
};
export default PQRAdmin;
