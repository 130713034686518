import { useEffect, useState } from "react";
import logo from "../images/icons/GETCAR_COLOR.png";
import logopequeño from "../images/inicial-07.png";


import Cordinadora from "../images/slider/Cordinadora.png";
import Deprisa from "../images/slider/Deprisa.png";
import Dhl from "../images/slider/Dhl.png";
import Envia from "../images/slider/Envia.png";
import FedEx from "../images/slider/FedEx.png";
import InterRapidisimo from "../images/slider/Inter Rapidisimo.png";
import MensajerosUrbanos from "../images/slider/Mensajeros urbanos..png";
import Moova from "../images/slider/Moova.png";
import Saferbo from "../images/slider/Saferbo.png";
import Tcc from "../images/slider/Tcc.png";
import EnlacesLogisticos from "../images/slider/Logo-enlaces-1.webp";

import Facebook from "../images/icons/facebook.png";
import Instagram from "../images/icons/instagram.png";
import Whatsapp from "../images/icons/whatsapp.png";
import Button from "react-bootstrap/Button";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";

import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import "./Login.css";

import { API_URL, showMessage } from "../util/util";

export const Login = () => {
  let navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem("authData")) {
      navigate("/Formularios");
    }
    if (localStorage.getItem("authDataAdmin")) {
      navigate("/Admin");
    }
  };

  const [formData, setFormData] = useState({
    user: "",
    password: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const clientResponse = await sendAuthApi("auth");
    const adminResponse = await sendAuthApi("authAdmin");

    if (clientResponse && clientResponse.token && clientResponse.token !== "") {
      localStorage.setItem("authData", JSON.stringify(clientResponse));
      localStorage.setItem("lastLoginTime", new Date().toISOString()); // Almacena la hora de la última sesión como una cadena ISO
      navigate("/Formularios");
      showMessage("success!", "Usuario Activado", "success", "Continuar");
    } else if (adminResponse && adminResponse.token && adminResponse.token !== "") {
      localStorage.setItem("authDataAdmin", JSON.stringify(adminResponse));
      localStorage.setItem("lastLoginTime", new Date().toISOString()); // Almacena la hora de la última sesión como una cadena ISO
      navigate("/Admin");
      showMessage("success!", "Bienvenido Administrador", "success", "Continuar");
    } else {
      showMessage("Error!", "Credenciales inválidas", "error", "Reintentar");
    }
  };

  const sendAuthApi = async (endpoint) => {
    const requestData = {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-type": "application/json",
      },
    };
    let response = await fetch(API_URL + endpoint, requestData);
    response = await response.json();
    return response;
  };

  return (
    <div>
      <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Inicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Tu Aliado de confianza</h1>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="/">
                    Inicio
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Registro">
                  Registro usuarios
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Login">
                    Portal GetCar
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div class="container">
        <div class="row px-3">
          <div class="col-lg-10 col-xl-9 card flex-row mx-auto px-0">
            <div class="img-left d-none d-md-flex"></div>

            <div class="card-body">
              <h4 class="title text-center mt-4">Inicia Sesion</h4>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="email" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="email"
                    placeholder="Ingresa tu Correo"
                    name="user"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="password" controlId="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="password"
                    placeholder="Ingresa tu contraseña"
                    name="password"
                    onChange={handleChange}
                  />
                </Form.Group>

                <div className="buttonLogin">
                  <Button variant="btn btn-success" type="submit">
                    Iniciar sesion
                  </Button>
                </div>

                <div class="text-center mb-2">
                  No tienes cuenta?
                  <a href="/Registro" class="register-link">
                    Registate
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <footer class="pie-pagina">
        <div class="grupo-1">
          <div class="box1">
            <h2>Alianza estratégica con</h2>
            <img src={EnlacesLogisticos} alt="" width="250px" />
            <h2>Y el respaldo de </h2>
            <div class="sliderFotter">
              <div class="slide-track-Fotter">
                <div class="slideFotter">
                  <img src={Cordinadora} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Deprisa} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Dhl} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Envia} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={FedEx} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={InterRapidisimo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={MensajerosUrbanos} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Moova} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Saferbo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Tcc} alt=""></img>
                </div>

                <div class="slideFotter">
                  <img src={Cordinadora} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Deprisa} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Dhl} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Envia} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={FedEx} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={InterRapidisimo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={MensajerosUrbanos} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Moova} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Saferbo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Tcc} alt=""></img>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="contact-info-footer">
              <h3>Contacto</h3>
              <p>
                <FaMapMarkerAlt className="icon" /> Direccion: Ciudad principal : Bogotá-Colombia 
              </p>
              <p>
                <FaEnvelope className="icon" /> Correo electrónico:
                GetCar@gmail.com
              </p>
              <p>
                <FaPhone className="icon" /> +57 3212827595 
              </p>
              <div class="red-social">
                <a href="https://es-la.facebook.com/GetcarLogistica/">
                  <img src={Facebook} alt="" width="40px" />
                </a>
                <a href="https://www.instagram.com/getcar_power/">
                  <img src={Instagram} alt="" width="40px" />
                </a>
                <a href="https://wa.me/message/HFB3FNAT67UPJ1">
                  <img src={Whatsapp} alt="" width="40px" />
                </a>
              </div>
            </div>

            <ul class="footer-links">
              <h3>Enlaces</h3>
              <li>
                <a href="#">Inicio</a>
              </li>
              <li>
                <a href="/Registro">Registro usuarios</a>
              </li>
              <li>
                <a href="/Login">Portal GetCar</a>
              </li>
            </ul>
            <div class="quienes-somos">
              <h3>Quiénes somos</h3>
              <p>
                Somos aliados en transporte de carga y mensajería para mipymes
                colombianas. Soluciones logísticas flexibles y seguras a nivel
                nacional.
              </p>
            </div>
          </div>
        </div>
        <div class="grupo-2">
          <small>
            <img src={logopequeño} width="15px" height="15px" />
            |&copy;2023 - Todos los derechos reservados.
          </small>
        </div>
      </footer>
    </div>
  );
};
