import React from "react";
import logo from "../images/icons/GETCAR_COLOR.png";
import logopequeño from "../images/inicial-07.png";

import Cordinadora from "../images/slider/Cordinadora.png";
import Deprisa from "../images/slider/Deprisa.png";
import Dhl from "../images/slider/Dhl.png";
import Envia from "../images/slider/Envia.png";
import FedEx from "../images/slider/FedEx.png";
import InterRapidisimo from "../images/slider/Inter Rapidisimo.png";
import MensajerosUrbanos from "../images/slider/Mensajeros urbanos..png";
import Moova from "../images/slider/Moova.png";
import Saferbo from "../images/slider/Saferbo.png";
import Tcc from "../images/slider/Tcc.png";
import EnlacesLogisticos from "../images/slider/Logo-enlaces-1.webp";

import Facebook from "../images/icons/facebook (1).png";
import Instagram from "../images/icons/instagram.png";
import Whatsapp from "../images/icons/whatsapp.png";

import QuienesSomos from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0002.webp";
import valoragregado from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0003.webp";
import CargaSeca from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0004.webp";
import CargaRefrigerada from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0005.webp";
import LllantaSencilla from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0006.webp";
import Minivan from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0007.webp";
import Carry from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0008.webp";
import MotoBaja from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0009.webp";
import Envios from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0010.webp";
import Proceso from "../images/Carrusel/Presentacióncorporativa Getcar 2023 página web (Ajustado).pptx_page-0011.webp";

import Video1 from "../images/Videos/PORTADA 2021.mp4";
import Video2 from "../images/Videos/COMPOSICIÓN 1.mp4";
import Video3 from "../images/Videos/portada instagram.mp4";

import { Swiper, SwiperSlide } from "swiper/react";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Inicio.css";

import { Pagination, Navigation } from "swiper";

function scrollToVideos(event) {
  event.preventDefault(); // Evita que se produzca la navegación predeterminada al hacer clic en el enlace

  const videosSection = document.getElementById("videos");
  videosSection.scrollIntoView({ behavior: "smooth" });
}

function Inicio() {
  return (
    <div>
      <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
        <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Inicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Tu aliado de confianza</h1>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">
                    Inicio
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    className="nav-link"
                    href="#videos"
                    onClick={scrollToVideos}
                  >
                    Videos
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Registro">
                    Registro usuarios
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Login">
                    Portal GetCar
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/Cotizador">
                    Cotizador
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="main">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={QuienesSomos} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={valoragregado} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={CargaSeca} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={CargaRefrigerada} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={LllantaSencilla} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Minivan} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Carry} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={MotoBaja} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Envios} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Proceso} />
          </SwiperSlide>
        </Swiper>
      </main>
      <div className="content-container" id="videos">
        <section className="videos-section">
          <h2>Videos</h2>
          <Carousel
            showArrows={false}
            infiniteLoop={true}
            emulateTouch={true}
            showStatus={false}
            autoPlay={false}
          >
            <div>
              <iframe
                width="560"
                height="315"
                src={Video1}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                muted
              ></iframe>{" "}
            </div>
            <div>
              <iframe
                width="560"
                height="315"
                src={Video2}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                muted
              ></iframe>{" "}
            </div>
            <div>
              <iframe
                width="560"
                height="315"
                src={Video3}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                muted
              ></iframe>{" "}
            </div>
          </Carousel>
        </section>
      </div>

      <footer class="pie-pagina">
        <div class="grupo-1">
          <div class="box1">
            <h2>Alianza estratégica con</h2>
            <img src={EnlacesLogisticos} alt="" width="250px" />
            <h2>Y el respaldo de </h2>
            <div class="sliderFotter">
              <div class="slide-track-Fotter">
                <div class="slideFotter">
                  <img src={Cordinadora} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Deprisa} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Dhl} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Envia} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={FedEx} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={InterRapidisimo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={MensajerosUrbanos} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Moova} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Saferbo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Tcc} alt=""></img>
                </div>

                <div class="slideFotter">
                  <img src={Cordinadora} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Deprisa} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Dhl} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Envia} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={FedEx} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={InterRapidisimo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={MensajerosUrbanos} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Moova} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Saferbo} alt=""></img>
                </div>
                <div class="slideFotter">
                  <img src={Tcc} alt=""></img>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="contact-info-footer">
              <h3>Contacto</h3>
              <p>
                <FaMapMarkerAlt className="icon" /> Direccion: Ciudad principal
                : Bogotá-Colombia
              </p>
              <p>
                <FaEnvelope className="icon" /> Correo electrónico:
                GetCar@gmail.com
              </p>
              <p>
                <FaPhone className="icon" /> +57 3212827595
              </p>
              <div class="red-social">
                <a href="https://es-la.facebook.com/GetcarLogistica/">
                  <img src={Facebook} alt="" width="40px" />
                </a>
                <a href="https://www.instagram.com/getcar_power/">
                  <img src={Instagram} alt="" width="40px" />
                </a>
                <a href="https://wa.me/message/HFB3FNAT67UPJ1">
                  <img src={Whatsapp} alt="" width="40px" />
                </a>
              </div>
            </div>

            <ul class="footer-links">
              <h3>Enlaces</h3>
              <li>
                <a href="#">Inicio</a>
              </li>
              <li>
                <a href="#videos" onClick={scrollToVideos}>
                  Clientes
                </a>
              </li>
              <li>
                <a href="/Registro">Registro usuarios</a>
              </li>
              <li>
                <a href="/Login">Portal GetCar</a>
              </li>
            </ul>
            <div class="quienes-somos">
              <h3>Quiénes somos</h3>
              <p>
                Somos aliados en transporte de carga y mensajería para mipymes
                colombianas. Soluciones logísticas flexibles y seguras a nivel
                nacional.
              </p>
            </div>
          </div>
        </div>
        <div class="grupo-2">
          <small>
            <img src={logopequeño} width="15px" height="15px" />
            |&copy;2023 - Todos los derechos reservados.
          </small>
        </div>
      </footer>
    </div>
  );
}

export default Inicio;
