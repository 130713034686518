import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/icons/GETCAR_COLOR.png";

import { API_URL, showMessage } from "../util/util";
import { RiCamera2Fill } from "react-icons/ri";
import { Navbar, Nav } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";

import "./Formularios.css";

function Formularios() {
  return (
    <div>
            <div>
        <html lang="en" />
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </head>
      </div>
      <div className="top-section">
      <div className="contact-info">
          <p>Teléfono: +57 3212827595 / Ciudad principal : Bogotá-Colombia </p>
        </div>
        <div className="social-media">
          {/* Agrega aquí los enlaces a tus redes sociales */}
        </div>
      </div>
      <header className="header_Inicio">
        <nav class="navbar navbar-expand-lg bg-body-tertiary w-100">
          <div class="container-fluid d-flex">
            <img src={logo} alt="Logo de la página" className="logo_Inicio" />
            <h1 className="titulo-inicio">Tu Aliado de confianza</h1>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
      </header>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4 Formularios">
            <div className="row card-deck">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Solicitud de servicios</h5>
                    <p className="card-text">Descripción del Contrato</p>
                    <a href="/SolicitudServicios" className="btn btn-primary">
                      Solicitar un servicio
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Guia de transportador</h5>
                    <p className="card-text">Descripción del Contrato</p>
                    <a href="/ServiciosTransporte" className="btn btn-primary">
                      Ir a la guia
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Formularios;
