import React, { useEffect, useState } from "react";
import { API_URL, showMessage } from "../util/util";
import { parse } from "date-fns";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel, 
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PictureAsPdf as PdfIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Importa el módulo autotable
import logo from "../images/icons/GETCAR_COLOR.png";

import "./HistoricoViajes.css";

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const [editingClient, setEditingClient] = useState(null);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTermService, setSearchTermService] = useState(""); // Agregar esta variable
  const [searchTermClient, setSearchTermClient] = useState("");
  const [originalClients, setOriginalClients] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isDescendingOrder, setIsDescendingOrder] = useState(true);
  const authDataAdmin = localStorage.getItem("authDataAdmin");
  let navigate = useNavigate();

  useEffect(() => {
    if (!authDataAdmin) {
      navigate("/");
      showMessage(
        "Error!",
        " No tienes los permisos para acceder",
        "error",
        "Reintentar"
      );
    }
  }, []);
  useEffect(() => {
    // Fetch the data from the API endpoint
    fetch(API_URL + "service/clients")
      .then((response) => response.json())
      .then((data) => {
        // Ordena los clientes por fecha de forma descendente (los más recientes primero)
        data.sort((a, b) => {
          const dateA = parse(a.date, "dd/MM/yyyy HH:mm:ss", new Date());
          const dateB = parse(b.date, "dd/MM/yyyy HH:mm:ss", new Date());
          return dateB - dateA;
        });
        setClients(data);
        setOriginalClients(data);
      })
      .catch((error) => {
        console.error("Error fetching clients data:", error);
      });
  }, []);

  const handleDeleteServicio = (serviceId) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar este servicio?"
    );

    if (confirmDelete) {
      fetch(API_URL + `service/service/${serviceId}/delete`, {
        method: "DELETE",
      }).then((response) => {
        if (response.status === 200) {
          // La eliminación se realizó con éxito, muestra un mensaje de éxito
          alert("Servicio eliminado con éxito");
          // Actualiza la lista de servicios después de la eliminación
          window.location.reload();
        } else {
          // Manejar el error
          console.error("Error al eliminar el servicio.");
        }
      });
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    setIsSearching(true);

    // Definir las variables parsedStartDate y parsedEndDate
    const parsedStartDate = startDate
      ? parse(startDate, "yyyy-MM-dd", new Date())
      : null;
    const parsedEndDate = endDate
      ? parse(endDate, "yyyy-MM-dd", new Date())
      : null;

    // Filtrar por fechas si se ingresan fechas
    const filteredClientsByDate =
      startDate && endDate
        ? clients.filter((client) => {
            const clientDate = parse(
              client.date.substring(0, 10),
              "dd/MM/yyyy",
              new Date()
            );

            return clientDate >= parsedStartDate && clientDate <= parsedEndDate;
          })
        : clients;

    // Filtrar por nombre del servicio si se ingresa un término de búsqueda
    const filteredClientsByService = searchTermService
      ? filteredClientsByDate.filter((client) => {
          return client.nombreServicio
            .toLowerCase()
            .includes(searchTermService.toLowerCase());
        })
      : filteredClientsByDate;

    // Filtrar por nombre del cliente si se ingresa un término de búsqueda
    const filteredClientsByName = searchTermClient
      ? filteredClientsByService.filter((client) => {
          return client.empresaCliente
            .toLowerCase()
            .includes(searchTermClient.toLowerCase());
        })
      : filteredClientsByService;

    // Actualizar el estado clients con los resultados del filtro
    setClients(filteredClientsByName);
    if (searchTermService === "" && searchTermClient === "") {
      setIsSearching(false);
    }
  };

  const clearFilter = () => {
    setClients([...originalClients]);
    setIsSearching(false); // Restablecer isSearching a false
    // Restablecer los campos de búsqueda
    setSearchTermService("");
    setSearchTermClient("");
    setStartDate("");
    setEndDate("");
  };

  const toggleSortOrder = () => {
    // Cambiar el orden al contrario del orden actual
    setIsDescendingOrder(!isDescendingOrder);

    // Ordenar la lista de clientes en consecuencia
    const sortedForms = [...clients].sort((a, b) => {
      const dateA = parse(a.date, "dd/MM/yyyy HH:mm:ss", new Date()).getTime();
      const dateB = parse(b.date, "dd/MM/yyyy HH:mm:ss", new Date()).getTime();

      return isDescendingOrder ? dateB - dateA : dateA - dateB;
    });
    // Actualizar la lista de clientes con el nuevo orden
    setClients(sortedForms);
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape", 
      fontSize: 6, 
    });

    doc.autoTable({
        cellStyles: {
    // Permite el ajuste automático del texto dentro de las celdas
    cellWidth: "wrap",
  },
      head: [
        [
          "Empresa Cliente",
          "Ciudad",
          "Nombre Vendedor",
          "Nombre Servicio",
          "Servicio",
          "Refrigerado",
          "Tipo Servicio",
          "Direccion Recogida",
          "Hora-Inicio",
          "Hora-Final",
          "Persona Encargada",
          "Observaciones",
          "Estado",
        ],
      ],
      body: clients.map((client) => [
        client.empresaCliente,
        client.ciudad,
        client.nombreVendedor,
        client.nombreServicio,
        client.servicio,
        client.vehiculoRefrigerado,
        client.tipoServicio,
        client.direccionVehiculo,
        client.horaInicio,
        client.horaFinal,
        client.nombreConductor,
        client.observaciones,
        client.completado ? "Completado" : "Pendiente",
      ]),
    });
    // Cargar la imagen de la marca de agua
    const watermarkImg = new Image();
    watermarkImg.src = logo; // Asignar la variable logo como fuente

    // Esperar a que se cargue la imagen antes de continuar
    watermarkImg.onload = function () {
      // Agregar la imagen de la marca de agua en la esquina inferior derecha
      const logoWidth = 50; // Ancho deseado del logo
      const logoHeight = (logoWidth / watermarkImg.width) * watermarkImg.height;

      const xPosLogo = doc.internal.pageSize.getWidth() - logoWidth - 10; // Ajusta el margen derecho
      const yPosLogo = doc.internal.pageSize.getHeight() - logoHeight - 10; // Ajusta el margen inferior

      doc.addImage(
        watermarkImg,
        "PNG",
        xPosLogo,
        yPosLogo,
        logoWidth,
        logoHeight
      );

// Ajustar el tamaño de fuente para el texto adicional
      doc.setFontSize(14); // Puedes ajustar el tamaño según tus necesidades

      // Agregar texto en la parte inferior izquierda
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();

      doc.text(
        "Historico de viajes solicitados",
        10,
        doc.internal.pageSize.getHeight() - 20
      );
      doc.text(
        `Generado el: ${formattedDate}`,
        10,
        doc.internal.pageSize.getHeight() - 10
      );

      // Restaurar el tamaño de fuente original
      doc.setFontSize(6);

      doc.save("Historico_Viajes.pdf");
    };
  };
  const handleEdit = (client) => {
    // Set the client data to be edited
    setEditingClient(client);
    setOpen(true);
  };

  const handleSave = () => {
    // Make a PUT request to update the client data in the backend
    fetch(API_URL + `service/clients/${editingClient.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editingClient),
    })
      .then((response) => {
        if (response.ok) {
          // If the update is successful, close the edit dialog and update the client data in the state
          setOpen(false);
          const updatedClients = clients.map((client) =>
            client.id === editingClient.id
              ? { ...client, ...editingClient }
              : client
          );
          setClients(updatedClients);
          setEditingClient(null);
        }
      })
      .catch((error) => {
        console.error("Error updating client data:", error);
      });
  };

  const handleClose = () => {
    // Close the edit dialog without saving changes
    setOpen(false);
    setEditingClient(null);
  };

  const formStyle = {
    padding: "15px",
    marginBottom: "10px",
  };

  return (
    <>
      <h1> Historico de viajes solicitados</h1>
      <div style={formStyle}>
        <form onSubmit={handleFilter}>
          <TextField
            type="text"
            label="Buscar por nombre del servicio"
            value={searchTermService}
            onChange={(e) => setSearchTermService(e.target.value)}
            disabled={isSearching}
          />

          <TextField
            type="text"
            label="Buscar por nombre del cliente"
            value={searchTermClient}
            onChange={(e) => setSearchTermClient(e.target.value)}
            disabled={isSearching}
          />

          <TextField
            type="date"
            label="Fecha de inicio"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            disabled={isSearching} // Deshabilita el campo cuando isSearching es true
          />
          <TextField
            type="date"
            label="Fecha de fin"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            disabled={isSearching} // Deshabilita el campo cuando isSearching es true
          />

          <Button type="submit" variant="contained" color="primary">
            Filtrar
          </Button>
          <Button variant="contained" color="secondary" onClick={clearFilter}>
            Borrar filtro
          </Button>
        </form>
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PdfIcon />}
        onClick={downloadPDF}
      >
        Descargar PDF
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="clients table">
          <TableHead>
            <TableRow>
              <TableCell>Id Servicio </TableCell>
              <TableCell>
                {/* Agregar un botón para cambiar el orden de fecha */}
                <Button
                  variant="text"
                  color="primary"
                  onClick={toggleSortOrder}
                >
                  Fecha {isDescendingOrder ? "▼" : "▲"}
                </Button>
              </TableCell>{" "}
              <TableCell>Nombre Servicio </TableCell>
              <TableCell>Empresa Cliente</TableCell>
              <TableCell>Nit/Cédula</TableCell>
              <TableCell>Dirección</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Ciudad</TableCell>
              <TableCell>Nombre Vendedor</TableCell>
              <TableCell>Valor Servicio</TableCell>
              <TableCell>Pago</TableCell>
              <TableCell>Servicio</TableCell>
              <TableCell>Vehículo Refrigerado</TableCell>
              <TableCell>Tipo Servicio</TableCell>
              <TableCell>Hora Inicio</TableCell>
              <TableCell>Hora Final</TableCell>
              <TableCell>Persona encargada</TableCell>
              <TableCell>Num Cedula</TableCell>
              <TableCell>Celular</TableCell>
              <TableCell>Direccion Vehiculo</TableCell>
              <TableCell>Peso Carga</TableCell>
              <TableCell>Observaciones</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Orden de compra</TableCell>
              <TableCell>Editar</TableCell>
              <TableCell>Eliminar Servicio </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell>{client.id}</TableCell>
                <TableCell>{client.date}</TableCell>
                <TableCell>{client.nombreServicio}</TableCell>
                <TableCell>{client.empresaCliente}</TableCell>
                <TableCell>{client.nitCedula}</TableCell>
                <TableCell>{client.direccion}</TableCell>
                <TableCell>{client.telefono}</TableCell>
                <TableCell>{client.ciudad}</TableCell>
                <TableCell>{client.nombreVendedor}</TableCell>
                <TableCell>{client.valorServicio}</TableCell>
                <TableCell>{client.pago}</TableCell>
                <TableCell>{client.servicio}</TableCell>
                <TableCell>{client.vehiculoRefrigerado}</TableCell>
                <TableCell>{client.tipoServicio}</TableCell>
                <TableCell>{client.horaInicio}</TableCell>
                <TableCell>{client.horaFinal}</TableCell>
                <TableCell>{client.nombreConductor}</TableCell>
                <TableCell>{client.numCedula}</TableCell>
                <TableCell>{client.celular}</TableCell>
                <TableCell>{client.direccionVehiculo}</TableCell>
                <TableCell>{client.pesoCarga}</TableCell>
                <TableCell>{client.observaciones}</TableCell>
                <TableCell>
                  {client.completado ? (
                    <span style={{ color: "green" }}>Completado</span>
                  ) : (
                    <span style={{ color: "red" }}>Pendiente</span>
                  )}
                </TableCell>
                <TableCell>
                  {client.ordenCompraFile && (
                    <a
                      href={`data:application/pdf;base64,${client.ordenCompraFile}`}
                      download={`factura_${client.id}.pdf`}
                    >
                      Descargar Orden
                    </a>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(client)}
                  >
                    Editar
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDeleteServicio(client.id)}
                  >
                    Eliminar Servicio
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Cliente</DialogTitle>
        <DialogContent>
          {editingClient && (
            <form>
              <TextField
                label="Empresa Cliente"
                value={editingClient.empresaCliente}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    empresaCliente: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Nit/Cédula"
                value={editingClient.nitCedula}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    nitCedula: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Dirección"
                value={editingClient.direccion}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    direccion: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Teléfono"
                value={editingClient.telefono}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    telefono: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Ciudad"
                value={editingClient.ciudad}
                onChange={(e) =>
                  setEditingClient({ ...editingClient, ciudad: e.target.value })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Nombre Vendedor"
                value={editingClient.nombreVendedor}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    nombreVendedor: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Valor Servicio"
                value={editingClient.valorServicio}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    valorServicio: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Pago"
                value={editingClient.pago}
                onChange={(e) =>
                  setEditingClient({ ...editingClient, pago: e.target.value })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Servicio"
                value={editingClient.servicio}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    servicio: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Vehículo Refrigerado"
                value={editingClient.vehiculoRefrigerado}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    vehiculoRefrigerado: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Tipo Servicio"
                value={editingClient.tipoServicio}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    tipoServicio: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Hora Inicio"
                value={editingClient.horaInicio}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    horaInicio: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Hora Final"
                value={editingClient.horaFinal}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    horaFinal: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Nombre Conductor"
                value={editingClient.nombreConductor}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    nombreConductor: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Num Cedula"
                value={editingClient.numCedula}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    numCedula: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Celular"
                value={editingClient.celular}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    celular: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Direccion Vehiculo"
                value={editingClient.direccionVehiculo}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    direccionVehiculo: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Peso Carga"
                value={editingClient.pesoCarga}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    pesoCarga: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <TextField
                label="Observaciones"
                value={editingClient.observaciones}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    observaciones: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    marginTop: 23, // Fuente en negrita para las etiquetas (labels)
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editingClient.completado}
                    onChange={(e) =>
                      setEditingClient({
                        ...editingClient,
                        completado: e.target.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label="Completado"
              />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientsTable;
