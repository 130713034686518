import React, { useState, useEffect } from "react";
import { API_URL, showMessage } from "../util/util";
import { useNavigate } from "react-router-dom";
import { parse } from "date-fns";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { PictureAsPdf as PdfIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import logo from "../images/icons/GETCAR_COLOR.png";

const GuideForms = () => {
  const [clientId, setClientId] = useState(null);
  const [clientForms, setClientForms] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTermService, setSearchTermService] = useState(""); // Agregar esta variable
  const [originalClients, setOriginalClients] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isDescendingOrder, setIsDescendingOrder] = useState(true);
  const authData = localStorage.getItem("authData");
  let navigate = useNavigate();

  useEffect(() => {
    if (!authData) {
      navigate("/");
      showMessage(
        "Error!",
        " No tienes los permisos para acceder",
        "error",
        "Reintentar"
      );
    } else {
      const parsedAuthData = JSON.parse(authData);
      setClientId(parsedAuthData.id);
      fetchClientForms(parsedAuthData.id);
    }
  }, [authData]);

  const fetchClientForms = async (clientId) => {
    try {
      const response = await fetch(
        API_URL + `service/clients/${clientId}/forms`
      );
      if (response.ok) {
        const forms = await response.json();
        forms.sort((a, b) => {
          const dateA = parse(a.date, "dd/MM/yyyy HH:mm:ss", new Date());
          const dateB = parse(b.date, "dd/MM/yyyy HH:mm:ss", new Date());
          return dateB - dateA;
        });
        setClientForms(forms);
        setOriginalClients(forms);
      } else {
        console.error("Error al obtener los formularios");
      }
    } catch (error) {
      console.error("Error al obtener los formularios:", error);
    }
  };

  const handleDeleteServicio = (serviceId) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar este servicio?"
    );

    if (confirmDelete) {
      fetch(API_URL + `service/service/${serviceId}/delete`, {
        method: "DELETE",
      }).then((response) => {
        if (response.status === 200) {
          // La eliminación se realizó con éxito, muestra un mensaje de éxito
          alert("Servicio eliminado con éxito");
          // Actualiza la lista de servicios después de la eliminación
          fetchClientForms(clientId);
        } else {
          // Manejar el error
          console.error("Error al eliminar el servicio.");
        }
      });
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    setIsSearching(true);

    // Definir las variables parsedStartDate y parsedEndDate
    const parsedStartDate = startDate
      ? parse(startDate, "yyyy-MM-dd", new Date())
      : null;
    const parsedEndDate = endDate
      ? parse(endDate, "yyyy-MM-dd", new Date())
      : null;

    // Filtrar por fechas si se ingresan fechas
    const filteredFormsByDate =
      startDate && endDate
        ? clientForms.filter((form) => {
            const formDate = parse(
              form.date.substring(0, 10),
              "dd/MM/yyyy",
              new Date()
            );

            return formDate >= parsedStartDate && formDate <= parsedEndDate;
          })
        : clientForms;

    // Filtrar por nombre del servicio si se ingresa un término de búsqueda
    const filteredFormsByName = searchTermService
      ? filteredFormsByDate.filter((form) => {
          return form.nombreServicio
            .toLowerCase()
            .includes(searchTermService.toLowerCase());
        })
      : filteredFormsByDate;

    // Actualizar el estado clientForms con los resultados del filtro
    setClientForms(filteredFormsByName);
    if (searchTermService === "") {
      setIsSearching(false);
    }
  };

  const toggleSortOrder = () => {
    setIsDescendingOrder(!isDescendingOrder);

    // Ordenar la lista de formularios en consecuencia
    const sortedForms = [...clientForms].sort((a, b) => {
      const dateA = parse(a.date, "dd/MM/yyyy HH:mm:ss", new Date()).getTime();
      const dateB = parse(b.date, "dd/MM/yyyy HH:mm:ss", new Date()).getTime();

      return isDescendingOrder ? dateB - dateA : dateA - dateB;
    });

    // Actualizar la lista de formularios con el nuevo orden
    setClientForms(sortedForms);
  };

  const clearFilter = () => {
    setClientForms([...originalClients]);
    setIsSearching(false); // Restablecer isSearching a false
    setSearchTermService(""); // Restablecer el término de búsqueda
    setStartDate(""); // Restablecer la fecha de inicio
    setEndDate(""); // Restablecer la fecha de fin
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      fontSize: 8,
    });

    doc.autoTable({
      cellStyles: {
        // Permite el ajuste automático del texto dentro de las celdas
        cellWidth: "wrap",
      },
      head: [
        [
          "Empresa Cliente",
          "Ciudad",
          "Nombre Vendedor",
          "Nombre Servicio",
          "Servicio",
          "Refrigerado",
          "Tipo Servicio",
          "Direccion Recogida",
          "Hora-Inicio",
          "Hora-Final",
          "Persona Encargada",
          "Observaciones",
          "Estado",
        ],
      ],
      body: clientForms.map((form) => [
        form.empresaCliente,
        form.ciudad,
        form.nombreVendedor,
        form.nombreServicio,
        form.servicio,
        form.vehiculoRefrigerado,
        form.tipoServicio,
        form.direccionVehiculo,
        form.horaInicio,
        form.horaFinal,
        form.nombreConductor,
        form.observaciones,
        form.completado ? "Completado" : "Pendiente",
      ]),
    });
    // Cargar la imagen de la marca de agua
    const watermarkImg = new Image();
    watermarkImg.src = logo; // Asignar la variable logo como fuente

    // Esperar a que se cargue la imagen antes de continuar
    watermarkImg.onload = function () {
      // Agregar la imagen de la marca de agua en la esquina inferior derecha
      const logoWidth = 50; // Ancho deseado del logo
      const logoHeight = (logoWidth / watermarkImg.width) * watermarkImg.height;

      const xPosLogo = doc.internal.pageSize.getWidth() - logoWidth - 10; // Ajusta el margen derecho
      const yPosLogo = doc.internal.pageSize.getHeight() - logoHeight - 10; // Ajusta el margen inferior

      doc.addImage(
        watermarkImg,
        "PNG",
        xPosLogo,
        yPosLogo,
        logoWidth,
        logoHeight
      );

      // Ajustar el tamaño de fuente para el texto adicional
      doc.setFontSize(14); // Puedes ajustar el tamaño según tus necesidades

      // Agregar texto en la parte inferior izquierda
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();

      doc.text(
        "Historico de viajes solicitados",
        10,
        doc.internal.pageSize.getHeight() - 20
      );
      doc.text(
        `Generado el: ${formattedDate}`,
        10,
        doc.internal.pageSize.getHeight() - 10
      );

      // Restaurar el tamaño de fuente original
      doc.setFontSize(6);

      doc.save("Historico_Viajes.pdf");
    };
  };

  const formStyle = {
    padding: "15px",
    marginBottom: "10px",
  };

  const isSameDay = (fechaSolicitud) => {
    if (fechaSolicitud && fechaSolicitud.match(/^\d{2}\/\d{2}\/\d{4}/)) {
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth() + 1; // Meses en JavaScript comienzan desde 0
      const year = today.getFullYear();
      const currentDate = `${day < 10 ? "0" : ""}${day}/${
        month < 10 ? "0" : ""
      }${month}/${year}`;

      // Extraer solo la fecha de la cadena de fecha y hora
      const fechaSolicitudDateOnly =
        fechaSolicitud.match(/^\d{2}\/\d{2}\/\d{4}/)[0];

      return currentDate === fechaSolicitudDateOnly;
    } else {
      console.log("Fecha de solicitud no válida:", fechaSolicitud);
      return false;
    }
  };

  return (
    <div>
      <h1> Historico de viajes solicitados</h1>
      <div style={formStyle}>
        <form onSubmit={handleFilter}>
          <TextField
            type="text"
            label="Buscar por nombre del servicio"
            value={searchTermService}
            onChange={(e) => setSearchTermService(e.target.value)}
            disabled={isSearching}
          />
          <TextField
            type="date"
            label="Fecha de inicio"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            disabled={isSearching}
          />
          <TextField
            type="date"
            label="Fecha de fin"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            disabled={isSearching}
          />
          <Button type="submit" variant="contained" color="primary">
            Filtrar
          </Button>
          .
          <Button variant="contained" color="secondary" onClick={clearFilter}>
            Borrar filtro
          </Button>
        </form>
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PdfIcon />}
        onClick={downloadPDF}
      >
        Descargar PDF
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="Formularios del Cliente">
          <TableHead>
            <TableRow>
              <TableCell>Fecha Solicitud </TableCell>
              <TableCell>
                {/* Agregar un botón para cambiar el orden de fecha */}
                <Button
                  variant="text"
                  color="primary"
                  onClick={toggleSortOrder}
                >
                  Fecha {isDescendingOrder ? "▼" : "▲"}
                </Button>
              </TableCell>{" "}
              <TableCell>Nombre Servicio </TableCell>
              <TableCell>Empresa Cliente</TableCell>
              <TableCell>Nit/Cédula</TableCell>
              <TableCell>Dirección</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Ciudad</TableCell>
              <TableCell>Nombre Vendedor</TableCell>
              <TableCell>Valor Servicio</TableCell>
              <TableCell>Pago</TableCell>
              <TableCell>Servicio</TableCell>
              <TableCell>Vehículo Refrigerado</TableCell>
              <TableCell>Tipo Servicio</TableCell>
              <TableCell>Hora Inicio</TableCell>
              <TableCell>Hora Final</TableCell>
              <TableCell>Persona encargada</TableCell>
              <TableCell>Num Cedula</TableCell>
              <TableCell>Celular</TableCell>
              <TableCell>Direccion Vehiculo</TableCell>
              <TableCell>Peso Carga</TableCell>
              <TableCell>Observaciones</TableCell>
              <TableCell>Orden de compra</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Eliminar Servicio </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientForms.map((form) => (
              <TableRow key={form.id}>
                <TableCell>{form.fechaSolicitud}</TableCell>
                <TableCell>{form.date}</TableCell>
                <TableCell>{form.nombreServicio}</TableCell>
                <TableCell>{form.empresaCliente}</TableCell>
                <TableCell>{form.nitCedula}</TableCell>
                <TableCell>{form.direccion}</TableCell>
                <TableCell>{form.telefono}</TableCell>
                <TableCell>{form.ciudad}</TableCell>
                <TableCell>{form.nombreVendedor}</TableCell>
                <TableCell>{form.valorServicio}</TableCell>
                <TableCell>{form.pago}</TableCell>
                <TableCell>{form.servicio}</TableCell>
                <TableCell>{form.vehiculoRefrigerado}</TableCell>
                <TableCell>{form.tipoServicio}</TableCell>
                <TableCell>{form.horaInicio}</TableCell>
                <TableCell>{form.horaFinal}</TableCell>
                <TableCell>{form.nombreConductor}</TableCell>
                <TableCell>{form.numCedula}</TableCell>
                <TableCell>{form.celular}</TableCell>
                <TableCell>{form.direccionVehiculo}</TableCell>
                <TableCell>{form.pesoCarga}</TableCell>
                <TableCell>{form.observaciones}</TableCell>
                <TableCell>
                  {form.ordenCompraFile && (
                    <a
                      href={`data:application/pdf;base64,${form.ordenCompraFile}`}
                      download={`factura_${form.id}.pdf`}
                    >
                      Descargar Orden
                    </a>
                  )}
                </TableCell>
                <TableCell>
                  {form.completado ? (
                    <span style={{ color: "green" }}>Completado</span>
                  ) : (
                    <span style={{ color: "red" }}>Pendiente</span>
                  )}
                </TableCell>
                <TableCell>
                  {isSameDay(form.fechaSolicitud) ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleDeleteServicio(form.id)}
                    >
                      Eliminar Servicio
                    </Button>
                  ) : (
                    "No se puede eliminar servicios de otro día"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GuideForms;
